import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image

function Tietosuojaseloste() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, // Ensure the content fills available space
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', // Ensure the content fills available space
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Rahoituskanava.fi tietosuojaseloste
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >

päivitetty 31.05.2024
<br/><br/>
<p>
Tässä selosteessa kerromme, miten keräämme, käytämme, säilytämme ja suojaamme henkilötietojasi, kun käytät palveluitamme. Rahoituskanava.fi on sitoutunut suojaamaan yksityisyyttäsi ja varmistamaan, että tietojasi käsitellään turvallisesti ja lainmukaisesti.<br/>

Selosteessa käsitellään seuraavia aiheita: millaisia tietoja keräämme ja miksi, miten käytämme ja jaamme tietojasi, oikeutesi henkilötietoihisi liittyen ja miten suojaamme tietosi.
Pyydämme sinua lukemaan tämän selosteen huolellisesti. Jos sinulla on kysyttävää, ota meihin yhteyttä.            
</p>


<h3>Rekisterinpitäjä</h3>
Nimi: Pohjolan Rahoitusvertailu Oy<br/>
Y-tunnus: 3447957-4<br/>
Sähköposti: info@rahoituskanava.fi<br/>
Rekisteriasioista vastaava henkilö: Tietohallinto / Pohjolan Rahoitusvertailu Oy<br/>

<h3>Rekisterin nimi</h3>
Rahoituskanava.fi -verkkopalvelun asiakasrekisteri<br/>

<h3>Henkilötietojen käsittelyn tarkoitukset</h3>
Keräämme ja käsittelemme henkilötietoja seuraaviin tarkoituksiin:<br/>

<ul>
<li>Rahoituspalveluiden tarjoaminen yrityksille (yrityslainat, laskurahoitus)</li>
<li>Asiakaspalvelu</li>
<li>Palveluidemme markkinointi yrityksille</li>
<li>Tilastointi ja analysointi palvelun parantamiseksi</li>
<li>Rekisterin tietosisältö</li>
</ul>
<h3>Rekisteriin kerätään ja tallennetaan seuraavia tietoja:</h3>

<ul>
<li>Yrityksen nimi</li>
<li>Y-tunnus</li>
<li>Yrityksen edustajan nimi</li>
<li>Osoite</li>
<li>Sähköposti</li>
<li>Puhelinnumero</li>
<li>Tietoja verkkopalvelun käytöstä</li>
<li>Henkilötietojen lähteet</li>
</ul>
<h3>Henkilötiedot kerätään seuraavista lähteistä:</h3>

<ul>
        <li>Julkiset rekisterit (esim. Patentti- ja rekisterihallitus, Yhteisötietojärjestelmä)</li>
        <li>Suoraan yrityksiltä ja niiden edustajilta verkkopalvelumme kautta</li>
    </ul>

    <h3>Henkilötietojen säilytysaika</h3>
    <p>
        Säilytämme henkilötietoja viiden vuoden ajan, ellei ole syytä poistaa tietoja aikaisemmin.
    </p>

    <h3>Henkilötietojen luovutukset</h3>
    <p>Luovutamme henkilötietoja kolmansille osapuolille seuraavissa tapauksissa:</p>
    <ul>
        <li>Yhteistyökumppanit, kuten pankit ja rahoitusyhtiöt, palveluiden tarjoamiseksi</li>
        <li>Alihankkijat, jotka tukevat palveluidemme toimintaa</li>
    </ul>

    <h3>Henkilötietojen siirto EU- tai ETA-alueen ulkopuolelle</h3>
    <p>
        Henkilötietoja ei siirretä EU- tai ETA-alueen ulkopuolelle.
    </p>

    <h3>Rekisteröidyn oikeudet</h3>
    <p>Yrityksillä ja yritysten edustajilla on seuraavat oikeudet:</p>
    <ul>
        <li>Oikeus tarkastaa tiedot: Rekisteröity voi tarkastaa, mitä tietoja hänestä on tallennettu.</li>
        <li>Oikeus tietojen oikaisemiseen: Rekisteröidyllä on oikeus vaatia virheellisten tai vanhentuneiden tietojen oikaisemista.</li>
        <li>Oikeus vastustaa käsittelyä: Rekisteröidyllä on oikeus vastustaa henkilötietojen käsittelyä, mikäli he kokevat, että tietoja käsitellään lainvastaisesti.</li>
        <li>Oikeus tietojen poistamiseen: Rekisteröidyllä on oikeus vaatia tietojen poistamista rekisteristä, mikäli käsittelylle ei ole enää perustetta.</li>
    </ul>

    <h3>Valitusoikeus</h3>
    <p>
        Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle, jos hän katsoo, että henkilötietojen käsittelyssä rikotaan tietosuojasäännöksiä.
    </p>

    <h3>Tietosuojan toteuttaminen</h3>
    <p>
        Olemme toteuttaneet asianmukaiset tekniset ja organisatoriset toimenpiteet henkilötietojen suojaamiseksi. Näihin toimenpiteisiin kuuluvat mm. palomuurit, salausmenetelmät ja turvalliset tietoverkot.
    </p>

    <h3>Yhteystiedot</h3>
    <p>
        Mikäli sinulla on kysyttävää tästä tietosuojaselosteesta tai haluat käyttää rekisteröidyn oikeuksiasi, voit ottaa meihin yhteyttä sähköpostitse osoitteeseen info@rahoituskanava.fi.
    </p>

             </Typography>               
            </Container>
        </Box>
    );
}

export default Tietosuojaseloste;
