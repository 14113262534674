import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Typography,
    Container,
    Grid,
    Button
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    AccountBalance as AccountBalanceIcon,
    Info as InfoIcon,
    CompareArrows as CompareArrowsIcon,
    AttachMoney as AttachMoneyIcon,
    ThumbUp as ThumbUpIcon,
    VerifiedUser as VerifiedUserIcon,
    Chat as ChatIcon,
    Assessment as AssessmentIcon,
    Description as DescriptionIcon
} from '@mui/icons-material';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';

function MitenValmistautuaKilpailutukseenYrityslaina() {
    return (
        <>
            <Helmet>
                <title>Miten valmistaudun ja saan parhaan tuloksen kilpailutuksesta? | Yrityslaina</title>
                <meta name="description" content="Opas yrityslainan kilpailutukseen valmistautumisesta ja parhaan tuloksen saavuttamisesta." />
            </Helmet>
            <Box
                sx={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
                    }}
                />
                <Container
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
                        padding: { xs: 2, md: 4 },
                        borderRadius: 2,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        width: '100%',
                        textAlign: 'left',
                        color: '#333333', // Dark text color
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        mt: { xs: 8, md: 8 }, // Add top margin for mobile view
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h1" gutterBottom sx={{ fontSize: '2rem', fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: '#122652' }}>
                                <img src='/images/favicon-32x32.png' style={{ height: '2rem' }} alt="icon" />
                                {' '}Lue vinkit, miten valmistautua yrityslainan kilpailutukseen
                            </Typography>
                        </Grid>
                        
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <AccountBalanceIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Varmista yritystiedot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Varmista, että yrityksesi tiedot ovat ajan tasalla kotisivuillasi ja muissa kanavissa, jotta pankit ja rahoituslaitokset voivat helposti tarkistaa ne.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <InfoIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Päivitä taloustiedot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Pidä yrityksesi taloustiedot ajan tasalla. Varmista, että sinulla on viimeisimmät tilinpäätöstiedot ja väliaikatiedot valmiina.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <AssessmentIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Valmistaudu kuvaamaan liiketoimintasi
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Laadi tai mieti yrityksesi toiminnasta selkeä kertomus, joka sisältää yrityksesi tavoitteet ja taloudellisen tilanteen. Tämä auttaa lainanantajia ymmärtämään liiketoimintaasi.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <DescriptionIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Kerää kaikki tarvittavat asiakirjat
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Varmista, että sinulla on kaikki tarvittavat asiakirjat, kuten verotustiedot, kassavirtalaskelmat ja mahdolliset vakuustiedot valmiina.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <CompareArrowsIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Vertaa lainatarjouksia
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Vertaa eri pankkien ja rahoituslaitosten lainatarjouksia. Kiinnitä huomiota korkoihin, maksuehtoihin, ja mahdollisiin piilokuluihin.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <AttachMoneyIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Arvioi lainatarpeesi
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Arvioi tarkasti, kuinka paljon lainaa tarvitset ja mihin tarkoitukseen. Tämä auttaa löytämään parhaan rahoitusratkaisun liiketoiminnallesi.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <VerifiedUserIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Varmista vakuudet
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Varmista, että sinulla on riittävät vakuudet tarjota lainanantajille, mikäli se on tarpeen.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <ThumbUpIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Tutki asiakasarviot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Tutustu lainanantajien asiakasarvioihin ja kokemuksiin. Tämä voi auttaa valitsemaan luotettavan ja tarpeisiisi sopivan kumppanin.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <ChatIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Neuvottele ehdoista
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Ole valmis neuvottelemaan lainaehtoista. Lainanantajien tarjouksissa voi olla joustovaraa, jos osaat perustella tarpeesi hyvin.
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2, mb: 2 }}>
                                        Hae tästä yrityslaina yrityksellesi
                                    </Typography>

                                    <Button
                                variant="contained"
                                component={RouterLink}
                                to="/yrityslaina"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#122652', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                endIcon={<ArrowForwardIcon />}
                                aria-label="yrityslaina"                            >
                                Hae yrityslainaa
                            </Button>
                           </Box>
                           </Grid>



                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default MitenValmistautuaKilpailutukseenYrityslaina;
