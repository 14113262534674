// src/Yhteystiedot.js
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, Alert } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { API_URL } from '../constants';

function BrokerYhteystiedot() {
    const [formData, setFormData] = useState({ yTunnus: '', yrityksenNimi: '', email: '', puhelinnumero: '' });
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    const loadUserDetails = async () => {
        try {
            const response = await axios.get('${API_URL}/userdetails', {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setFormData(response.data);
        } catch (error) {
            console.error('Failed to load user details:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setMessage(null);

        if (!formData.yTunnus || !formData.email || !formData.puhelinnumero) {
            setError('Täytä kaikki pakolliset kentät (Y-tunnus, sähköposti, puhelinnumero).');
            return;
        }

        try {
            await axios.post(
                'https://localhost:3000/userdetails',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            setMessage('Yhteystiedot päivitetty onnistuneesti.');
        } catch (error) {
            console.error('Failed to update user details:', error);
            setError('Tietojen päivitys epäonnistui.');
        }
    };

    useEffect(() => {
        loadUserDetails();
    }, []);

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 8,
                    p: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    Päivitä yhteystiedot
                </Typography>
                <TextField
                    label="Y-tunnus"
                    name="yTunnus"
                    value={formData.yTunnus}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Yrityksen nimi"
                    name="yrityksenNimi"
                    value={formData.yrityksenNimi}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Sähköposti"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Puhelinnumero"
                    name="puhelinnumero"
                    value={formData.puhelinnumero}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                {message && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {message}
                    </Alert>
                )}
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Tallenna
                </Button>
            </Box>
        </Container>
    );
}

export default BrokerYhteystiedot;
