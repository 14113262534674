import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, TextField, Button, Alert } from '@mui/material';

function UusiSalasana() {
    const { guid } = useParams();
    const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage(null);
        setError(null);

        if (!formData.password || !formData.confirmPassword) {
            setError('Täytä molemmat kentät.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Salasanat eivät täsmää.');
            return;
        }

        try {
            const response = await fetch('https://localhost:44385/api/Users/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: guid, password: formData.password, email: '' }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Virhe salasanan vaihdossa.');
            }

            setMessage('Salasanan vaihtaminen onnistui.');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 8,
                    p: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    Uusi Salasana
                </Typography>
                <TextField
                    label="Uusi Salasana"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Vahvista Salasana"
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {message && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {message}
                    </Alert>
                )}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Vaihda Salasana
                </Button>
            </Box>
        </Container>
    );
}

export default UusiSalasana;
