import React from 'react';
import { Helmet } from 'react-helmet';

import Navbar from './Navbar';

import {
    Box,
    Typography,
    Container,
    Grid,
    Button
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, AccountBalance as AccountBalanceIcon, Info as InfoIcon, CompareArrows as CompareArrowsIcon, AttachMoney as AttachMoneyIcon, ThumbUp as ThumbUpIcon, VerifiedUser as VerifiedUserIcon, Chat as ChatIcon } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


function MitenValmistautuaKilpailutukseenLaskurahoitus() {
    return (
        <>
            <Helmet>
                <title>Miten valmistaudun ja saan parhaan tuloksen kilpailutuksesta? | Laskurahoitus</title>
                <meta name="description" content="Opas laskurahoituksen kilpailutukseen valmistautumisesta ja parhaan tuloksen saavuttamisesta." />
            </Helmet>
            <Box
                sx={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
                    }}
                />
                <Container
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
                        padding: { xs: 2, md: 4 },
                        borderRadius: 2,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        width: '100%',
                        textAlign: 'left',
                        color: '#333333', // Dark text color
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        mt: { xs: 8, md: 8 }, // Add top margin for mobile view
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h1" gutterBottom sx={{ fontSize: '2rem', fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: '#122652' }}>
                                <img src='/images/favicon-32x32.png' style={{ height: '2rem' }} alt="icon" />
                                {' '}Lue vinkit, miten valmistautua laskurahoituksen kilpailutukseen ja tarjousten vertailuun
                            </Typography>
                        </Grid>
                        
                        <Grid container item spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <AccountBalanceIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Varmista yritystiedot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Varmista, että yrityksesi tiedot ovat ajan tasalla kotisivuillasi ja muissa kanavissa, jotta rahoitusyhtiöt voivat helposti tarkistaa ne.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <InfoIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Päivitä taloustiedot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Pidä yrityksesi taloustiedot ajan tasalla. Varmista, että sinulla on viimeisimmät tilinpäätöstiedot ja väliaikatiedot valmiina.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <CheckCircleIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Valmistaudu lähettämään myyntilaskutietoja
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Valmistaudu lähettämään myyntilaskutietoja, kuten aikaisemmat laskut ja tietoja laskujen maksajista, jotta rahoitusyhtiöt voivat arvioida riskit.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <CompareArrowsIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Vertaa tarjouksia
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Vertaa eri rahoitusyhtiöiden tarjouksia. Kiinnitä huomiota korkoihin, palvelumaksuihin, maksuehtoihin ja asiakaspalvelun laatuun.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <AttachMoneyIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Arvioi rahoituksen tarve
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Arvioi, onko rahoituksen tarve jatkuvaa vai kertaluonteista. Tämä vaikuttaa siihen, minkä tyyppistä rahoitusta kannattaa hakea.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <VerifiedUserIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Vakuudet
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Varmista, että sinulla on riittävät vakuudet tarjota rahoitusyhtiöille, mikäli se on tarpeen.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <ThumbUpIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Asiakasarviot
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Tutustu rahoitusyhtiöiden asiakasarvioihin ja kokemuksiin. Tämä voi auttaa valitsemaan luotettavan ja tarpeisiisi sopivan kumppanin.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                                    <ChatIcon sx={{ fontSize: 60, color: '#122652' }} />
                                    <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2 }}>
                                        Neuvottele ehdoista
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Lato, sans-serif', mt: 1 }}>
                                        Ole valmis neuvottelemaan ehdoista. Rahoitusyhtiöiden tarjouksissa voi olla joustovaraa, jos osaat perustella tarpeesi hyvin.
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', mt: 2, mb: 2 }}>
                                        Hae tästä laskurahoitusta yrityksellesi
                                    </Typography>

                                    <Button
                                variant="contained"
                                component={RouterLink}
                                to="/laskurahoitus"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#122652', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                endIcon={<ArrowForwardIcon />}
                                aria-label="laskurahoitus"                            >
                                Hee laskurahoitusta
                            </Button>
                           </Box>
                           </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default MitenValmistautuaKilpailutukseenLaskurahoitus;
