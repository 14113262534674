// index.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import chatReducer from './chatReducer';
import companyLoanApplicationsReducer from './companyLoanApplicationsReducer';
import invoiceFundingApplicationsReducer from './invoiceFundingApplicationsReducer';
import offerReducer from './offerReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  companyLoanApplications: companyLoanApplicationsReducer,
  invoiceFundingApplications: invoiceFundingApplicationsReducer,
  offers: offerReducer,
});

export default rootReducer;
