import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanyLoanApplications } from '../features/companyLoanApplications/companyLoanApplicationsActions';

const initialState = {
  loading: false,
  applications: [],
  error: '',
};

const companyLoanApplicationsSlice = createSlice({
  name: 'companyLoanApplications',
  initialState,
  reducers: {
    // You can keep synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyLoanApplications.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCompanyLoanApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.applications = action.payload;
      })
      .addCase(fetchCompanyLoanApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default companyLoanApplicationsSlice.reducer;
