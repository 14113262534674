

import React from 'react';
import { Container, Typography, Box, Link, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image

function About() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, // Ensure the content fills available space
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', // Ensure the content fills available space
                }}
            >

                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Rahoituskanava.fi 
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >

                    <h3>Palvelun tarjoaa Pohjolan Rahoitusvertailu Oy{' '}</h3>
     <p>               Voit olla yhteydessä asiakaspalveluumme osoitteessa <Link href="mailto:info@rahoituskanava.fi">info@rahoituskanava.fi</Link>{' '}<br/>
                    Yhteystiedot: Pohjolan Rahoitusvertailu Oy, Y-tunnus: 3447957-4{' '}
                    </p>
<p>
Tarjoamme Rahoituskanava.fi verkkopalvelun välityksellä korkealaatuisia rahoituspalveluiden vertailupalveluja yrityksille ja autamme löytämään yritykselle sopivimman rahoitusratkaisun, 

                    <Link component={RouterLink} to="/laskurahoitus" sx={{ textDecoration: 'underline', ml: 1 }}>laskurahoituskumppanin</Link> 
                    {' '} ja 
                    <Link component={RouterLink} to="/yrityslaina" sx={{ textDecoration: 'underline', ml: 1 }}>yrityslainan tarjoajan</Link>.
</p>                

                    <h3>Miksi käyttää rahoituksen vertailupalvelua?</h3>
                    <p>Yrityksen kannattaa käyttää vertailupalvelua parhaan rahoituspalvelun löytämiseksi, koska se tarjoaa mahdollisuuden vertailla eri vaihtoehtoja helposti ja nopeasti. Vertailupalvelun avulla yritys voi löytää itselleen edullisimman ja sopivimman rahoitusratkaisun ilman ylimääräistä vaivaa ja kustannuksia. Tämä säästää aikaa ja resursseja, jotka voidaan suunnata yrityksen ydintoimintoihin.</p>
            
                    <h3>Digitalisaation hyödyt kilpailutuksessa</h3>
                    <p>
                    Digitalisaatio on tuonut kilpailutuksen hyödyt kaikille yrityksille, riippumatta niiden koosta tai toimialasta. Digitaalisten vertailupalveluiden avulla yritykset voivat kilpailuttaa rahoitustarjoukset helposti verkossa, mikä lisää läpinäkyvyyttä ja kilpailua rahoitusmarkkinoilla. Tämä johtaa parempiin ehtoihin ja alhaisempiin kustannuksiin yrityksille. Lisäksi digitaalisten palveluiden avulla rahoitusprosessi on nopeampi ja tehokkaampi, mikä mahdollistaa nopeammat päätökset ja paremmat liiketoiminnan mahdollisuudet.
                    </p>

                    <h3>Tekoälyapuri Rahoitusluotsi palveluksessasi</h3>
                    <p>
                    Voit keskustella Rahoitusluotsin kanssa yrityksen rahoitustarpeista ja parhaan rahoituksen valinnasta ympäri vuorokauden. Väsymätön apurimme tekee parhaansa avustaaksesi yritystäsi parhaan ratkaisun löytämiseksi.
                    </p>


             </Typography>              
            </Container>
        </Box>
    );
}

export default About;
