import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { API_URL } from './constants';
import {
    Box,
    Button,
    TextField,
    Typography,
    Alert,
    Slider,
    Checkbox,
    FormControlLabel,
    Link,
    Grid,
    CircularProgress,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function NewYrityslaina() {
    const [formData, setFormData] = useState({
        yTunnus: '',
        email: '',
        puhelinnumero: '',
        lainamaara: 50000, // Default loan amount
        lainaAika: 12, // Default loan length in months
        korvausNykyisestaRahoituksesta: false, // Checkbox state for refinancing
        hyvaksyKayttoehdot: false, // Terms acceptance state
        companyName: '',
        address: '',
        website: '',
    });
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'yTunnus') {
            if (validateYTunnus(value)) {
                fetchCompanyData(value);
            }
        }
    };

    const handleSliderChange = (name) => (event, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const calculateMonthlyPayment = (loanAmount, loanMonths) => {
        const interestRate = 0.05 / 12; // Example annual interest rate of 5% divided by 12 months
        const payment = (loanAmount * interestRate) / (1 - Math.pow(1 + interestRate, -loanMonths));
        return payment.toFixed(2);
    };

    const validateYTunnus = (yTunnus) => {
        const yTunnusRegex = /^\d{7}-\d{1}$/;
        return yTunnusRegex.test(yTunnus);
    };

    const fetchCompanyData = async (yTunnus) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://avoindata.prh.fi/bis/v1/${yTunnus}`);
            const data = response.data;

            if (data.results && data.results.length > 0) {
                const latestCompany = data.results[0];
                const latestName = latestCompany.names.find((n) => !n.endDate).name;
                const latestAddress = latestCompany.addresses.find((a) => !a.endDate);
                const website = latestCompany.contactDetails.find(
                    (c) => c.type.toLowerCase() === 'kotisivun www-osoite'
                )?.value || '';

                setFormData((prevData) => ({
                    ...prevData,
                    companyName: latestName,
                    address: `${latestAddress.street}, ${latestAddress.postCode} ${latestAddress.city}`,
                    website,
                }));
                setError(null);
            } else {
                setError('Yrityksen tietoja ei löytynyt.');
                setFormData((prevData) => ({
                    ...prevData,
                    companyName: '',
                    address: '',
                    website: '',
                }));
            }
        } catch (err) {
            console.error(err);
            setError('Virhe haettaessa yrityksen tietoja.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setMessage(null);

        if (!formData.hyvaksyKayttoehdot) {
            setError('Sinun on hyväksyttävä käyttöehdot.');
            return;
        }

        if (!validateYTunnus(formData.yTunnus)) {
            setError('Virheellinen Y-tunnus. Muoto pitää olla 1234567-8.');
            return;
        }

        try {
            const response = await axios.post(API_URL + 'api/CompanyLoanApplications/CreateWithUser', formData, {
                headers: { 'Content-Type': 'application/json' },
            });
            setMessage('Yrityslainapyyntö lähetetty onnistuneesti!');
            navigate('/kiitos-yrityslainahakemuksesta'); // Forward the user to Omatsivut
        } catch (err) {
            console.error(err);
            setError('Yrityslainapyynnön lähettäminen epäonnistui.');
        }
    };

    useEffect(() => {
        setMonthlyPayment(calculateMonthlyPayment(formData.lainamaara, formData.lainaAika));
    }, [formData.lainamaara, formData.lainaAika]);

    const textFieldRef = useRef(null);
    useEffect(() => {
        // Focus the TextField when the component mounts
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Yrityslainan kilpailutus ja vertailu yrityksellesi | Rahoituskanava</title>
                <meta name="description" content="Yrityslaina ylivoimaisin ehdoin. Kilpailuta pankit ja rahoituslaitokset ja löydä paras vaihtoehto yrityksellesi." />
                <meta name="keywords" content="yrityslaina, yritysrahoitus, kilpailutus, Rahoituskanava, kilpailutuspalvelu" />
            </Helmet>

            <Box
                sx={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
                    }}
                />
                <Container
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
                        padding: { xs: 2, md: 4 },
                        borderRadius: 2,
                        maxHeight: '90vh',
                        overflowY: 'auto',
                        width: '100%',
                        textAlign: 'left',
                        color: '#333333', // Dark text color
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        mt: { xs: 8, md: 8 }, // Add top margin for mobile view
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={5}>
                            <Typography variant="h1" gutterBottom sx={{ fontSize: '2rem', fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: '#122652' }}>
                                <img src='/images/favicon-32x32.png' style={{ height: '2rem'}}></img>{' '}Yrityslaina – investoi yrityksesi kasvuun ja kehitykseen
                            </Typography>
                            <Typography variant="h6" gutterBottom 
                            sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', fontSize: '1rem', marginTop: '2rem' }}>

                          

                            

                            <Grid item xs={12} sm={12} md={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                            Hae yrityslainaa yrityksesi investointeihin, käyttöpääomaksi tai lainojen yhdistämiseen. <br/>
                            Kilpailuta tarjoukset ja valitse yrityksellesi sopivin lainaehdot.<br/><br/>
                            Lue tästä ohjeistus miten valmistautua yrityslainan kilpailutukseen ja tarjousten vertailuun<br/><br/>
                                    <Button
                                variant="contained"
                                component={RouterLink}
                                to="/yrityslaina/miten-valmistautua-kilpailutukseen"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#122652', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                
                                aria-label="yrityslaina vinkit"                            >
                                Lue vinkit
                            </Button>

                           </Box></Grid>
                           </Typography>
                            
                            <List sx={{marginTop: '1.5rem'}}>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleIcon style={{color: '#122652'}} />
                                    </ListItemIcon>
                                    <ListItemText  
                                        primaryTypographyProps={{
                                        sx: { fontSize: {xs:'0.9rem', md: '1rem', xl:'1.3rem'}, mt: 0.5, fontFamily: 'Lato, sans-serif', color: '#333333'}} } 
                                    primary="Kilpailuttamalla saat yhteydenottoja ja tarjouksia useilta eri pankeilta ja rahoitusyhtiöiltä" />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckCircleIcon style={{ color: '#122652' }} />
                                    </ListItemIcon>
                                    <ListItemText                                          primaryTypographyProps={{
                                        sx: { fontSize: {xs:'0.9rem', md: '1rem', xl:'1.3rem'}, mt: 0.5, fontFamily: 'Lato, sans-serif', color: '#333333'}} }                                     
                                    primary="Rahoituskanavan palvelu on maksutonta etkä sitoudu mihinkään pyytäessäsi tarjouksia" />
                                </ListItem>
                                
                            </List>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            
                            <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: '#122652' }}>
                                Hae yrityslainaa tästä 
                            </Typography>
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '0.9rem', fontFamily: 'Lato, sans-serif', fontWeight: 'normal' }}>
                                
                                Täytä yrityksen Y-tunnus ja omat tietosi huolellisesti ja valitse yrityksellesi sopiva lainan määrä ja laina-aika.<br /><br />

                            </Typography>
                            <TextField
                                inputRef={textFieldRef}
                                label="Y-tunnus"
                                focused={true}
                                name="yTunnus"
                                value={formData.yTunnus}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                sx={{ mb: 1 }}
                                InputLabelProps={{ style: { color: '#333333' } }}
                                InputProps={{ style: { color: '#333333' } }}
                            />
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        {formData.companyName ? `Yrityksen nimi: ${formData.companyName}` : ''}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        {formData.address ? `Osoite: ${formData.address}` : ''} 
                                    </Typography>                                    
                                </>
                            )}
                            <TextField
                                label="Sähköposti"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                sx={{ mb: 1, marginTop: "10px" }}
                                InputLabelProps={{ style: { color: '#333333' } }}
                                InputProps={{ style: { color: '#333333' } }}
                            />
                            <TextField
                                label="Puhelinnumero"
                                name="puhelinnumero"
                                value={formData.puhelinnumero}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                sx={{ mb: 1 }}
                                InputLabelProps={{ style: { color: '#333333' } }}
                                InputProps={{ style: { color: '#333333' } }}
                            />

                            <Typography gutterBottom>Arvio lainamäärästä ({formData.lainamaara.toFixed(2)} €)</Typography>
                            <Slider
                                value={formData.lainamaara}
                                onChange={handleSliderChange('lainamaara')}
                                aria-labelledby="lainamaara-slider"
                                min={1000}
                                max={500000}
                                step={1000}
                                valueLabelDisplay="auto"
                                sx={{ mb: 2 }}
                            />
                            <Typography gutterBottom>Arvio laina-ajasta ({formData.lainaAika} kuukautta)</Typography>
                            <Slider
                                value={formData.lainaAika}
                                onChange={handleSliderChange('lainaAika')}
                                aria-labelledby="lainaAika-slider"
                                min={6}
                                max={60}
                                step={1}
                                valueLabelDisplay="auto"
                                sx={{ mb: 2 }}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox sx={{
                                        color: '#333333', // Default color                           
                                    }}
                                        checked={formData.korvausNykyisestaRahoituksesta}
                                        onChange={handleCheckboxChange}
                                        name="korvausNykyisestaRahoituksesta"
                                        color="primary"
                                    />
                                }
                                label="Yrityksellä on jo lainoja, mutta haluan yhdistää ne edullisempaan lainaan"

                                TypographyProps={{
                                    sx: { fontSize: '0.9rem', fontFamily: 'Lato, sans-serif', color: '#333333' },
                                }}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox sx={{
                                        color: '#333333', // Default color                           
                                    }}
                                        checked={formData.hyvaksyKayttoehdot}
                                        onChange={handleCheckboxChange}
                                        name="hyvaksyKayttoehdot"
                                        color="primary"
                                    />
                                }
                                label={
                                    <>
                                        Olen hyväksynyt{' '}
                                        <Link component={RouterLink} to="/kayttoehdot" underline="hover" sx={{ color: '#333333' }}>
                                            käyttöehdot
                                        </Link>
                                    </>
                                }

                                TypographyProps={{
                                    sx: { fontSize: '0.9rem', fontFamily: 'Lato, sans-serif', color: '#333333' },
                                }}
                            /><br />
                            <Typography variant="body1" gutterBottom sx={{ fontSize: '0.9rem', fontFamily: 'Lato, sans-serif', fontWeight: 'normal' }}>
                                Lähettämällä kilpailutuspyynnön välitämme hakemuksen eteenpäin välitömästi kumppaneillemme.<br /><br />

                            </Typography>
                            <Button endIcon={<ArrowForwardIcon />} type="submit" variant="contained" color="primary" sx={{ mt: 2 }}  onClick={handleSubmit} >
                                Hae yrityslainaa
                            </Button>
                            {message && (
                                <Alert severity="success" sx={{ mt: 2 }}>
                                    {message}
                                </Alert>
                            )}
                            {error && (
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    {error}
                                </Alert>
                            )}


                        </Grid>
                    </Grid>

                </Container>
            </Box>
        </>
    );
}

export default NewYrityslaina;
