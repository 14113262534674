import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Button,
    IconButton,
    Modal,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Grid
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { API_URL } from './constants';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const FinancialInstitutionsComponent = () => {
    const token = localStorage.getItem('token');
    const [financialInstitutions, setFinancialInstitutions] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('companyName');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentInstitution, setCurrentInstitution] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        fetchFinancialInstitutions();
    }, []);

    const fetchFinancialInstitutions = async () => {
        const response = await fetch(`${API_URL}api/FinancialInstitutions`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        setFinancialInstitutions(data);
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const sortedData = [...financialInstitutions].sort((a, b) => {
            if (a[property] < b[property]) return isAsc ? -1 : 1;
            if (a[property] > b[property]) return isAsc ? 1 : -1;
            return 0;
        });

        setFinancialInstitutions(sortedData);
    };

    const handleOpenModal = (institution) => {
        setCurrentInstitution(institution || {
            companyName: '',
            phone: '',
            bic: '',
            email: '',
            contactName: '',
            contactPosition: '',
            companyWebsiteUrl: '',
            companyLogoUrl: '',
            willOfferInvoiceFunding: false,
            willOfferCompanyLoan: false,
            willOfferConsumerLoan: false,
            isActive: true,
            sendApplicationsEmail: false,
            applicationsEmail: '',
            sendSelectionEmail: false,
            selectionsEmail: '',
            useCompanyLoanApplicationsApi: false,
            companyLoanApplicationsApiUrl: '',
            useInvoiceFundingApplicationsApi: false,
            invoiceFundingApplicationsApiUrl: ''
        });
        setIsEdit(!!institution);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setCurrentInstitution(null);
    };

    const handleSave = async () => {
        const method = isEdit ? 'PUT' : 'POST';
        const endpoint = isEdit ? `${API_URL}api/FinancialInstitutions/${currentInstitution.id}` : `${API_URL}api/FinancialInstitutions`;
        const response = await fetch(endpoint, {
            method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(currentInstitution)
        });

        if (response.ok) {
            fetchFinancialInstitutions();
            handleCloseModal();
        } else {
            console.error('Error saving financial institution:', response.statusText);
        }
    };

    const handleDelete = async (id) => {
        const response = await fetch(`${API_URL}api/FinancialInstitutions/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.ok) {
            fetchFinancialInstitutions();
        } else {
            console.error('Error deleting financial institution:', response.statusText);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setCurrentInstitution({
            ...currentInstitution,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h4">Financial Institutions</Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleOpenModal(null)}>
                    Add New
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {['companyName', 'phone', 'bic', 'email'].map((key) => (
                                <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === key}
                                        direction={orderBy === key ? order : 'asc'}
                                        onClick={() => handleSort(key)}
                                    >
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {financialInstitutions.map((institution) => (
                            <TableRow key={institution.id}>
                                <TableCell>{institution.companyName}</TableCell>
                                <TableCell>{institution.phone}</TableCell>
                                <TableCell>{institution.bic}</TableCell>
                                <TableCell>{institution.email}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleOpenModal(institution)}><EditIcon /></IconButton>
                                    <IconButton onClick={() => handleDelete(institution.id)}><DeleteIcon /></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" mb={2}>{isEdit ? 'Edit' : 'Add'} Financial Institution</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Company Name"
                                name="companyName"
                                value={currentInstitution?.companyName || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Phone"
                                name="phone"
                                value={currentInstitution?.phone || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="BIC"
                                name="bic"
                                value={currentInstitution?.bic || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={currentInstitution?.email || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Contact Name"
                                name="contactName"
                                value={currentInstitution?.contactName || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Contact Position"
                                name="contactPosition"
                                value={currentInstitution?.contactPosition || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Company Website URL"
                                name="companyWebsiteUrl"
                                value={currentInstitution?.companyWebsiteUrl || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Company Logo URL"
                                name="companyLogoUrl"
                                value={currentInstitution?.companyLogoUrl || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.willOfferInvoiceFunding || false}
                                        onChange={handleInputChange}
                                        name="willOfferInvoiceFunding"
                                        color="primary"
                                    />
                                }
                                label="Will Offer Invoice Funding"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.willOfferCompanyLoan || false}
                                        onChange={handleInputChange}
                                        name="willOfferCompanyLoan"
                                        color="primary"
                                    />
                                }
                                label="Will Offer Company Loan"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.willOfferConsumerLoan || false}
                                        onChange={handleInputChange}
                                        name="willOfferConsumerLoan"
                                        color="primary"
                                    />
                                }
                                label="Will Offer Consumer Loan"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.isActive || false}
                                        onChange={handleInputChange}
                                        name="isActive"
                                        color="primary"
                                    />
                                }
                                label="Is Active"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.sendApplicationsEmail || false}
                                        onChange={handleInputChange}
                                        name="sendApplicationsEmail"
                                        color="primary"
                                    />
                                }
                                label="Send Applications Email"
                            />
                            <TextField
                                label="Applications Email"
                                name="applicationsEmail"
                                value={currentInstitution?.applicationsEmail || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.sendSelectionEmail || false}
                                        onChange={handleInputChange}
                                        name="sendSelectionEmail"
                                        color="primary"
                                    />
                                }
                                label="Send Selection Email"
                            />
                            <TextField
                                label="Selections Email"
                                name="selectionsEmail"
                                value={currentInstitution?.selectionsEmail || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.useCompanyLoanApplicationsApi || false}
                                        onChange={handleInputChange}
                                        name="useCompanyLoanApplicationsApi"
                                        color="primary"
                                    />
                                }
                                label="Use Company Loan Applications API"
                            />
                            <TextField
                                label="Company Loan Applications API URL"
                                name="companyLoanApplicationsApiUrl"
                                value={currentInstitution?.companyLoanApplicationsApiUrl || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={currentInstitution?.useInvoiceFundingApplicationsApi || false}
                                        onChange={handleInputChange}
                                        name="useInvoiceFundingApplicationsApi"
                                        color="primary"
                                    />
                                }
                                label="Use Invoice Funding Applications API"
                            />
                            <TextField
                                label="Invoice Funding Applications API URL"
                                name="invoiceFundingApplicationsApiUrl"
                                value={currentInstitution?.invoiceFundingApplicationsApiUrl || ''}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button onClick={handleSave} variant="contained" color="primary">
                            Save
                        </Button>
                        <Button onClick={handleCloseModal} variant="outlined" color="secondary" sx={{ ml: 2 }}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default FinancialInstitutionsComponent;
