import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, clearMessages, setMessages } from '../../reducers/chatReducer';
import { sendMessage } from './chatSlice';
import ReactMarkdown from 'react-markdown';
import { ExpandMore, ExpandLess, Clear } from '@mui/icons-material';
import { CircularProgress, IconButton, Box, Typography, Button } from '@mui/material';
import './chatBot.css';
import TypewriterMessage from './TypewriterMessage';
import remarkGfm from 'remark-gfm';

const LOCAL_STORAGE_KEY = 'chatbotMessages';

const Chatbot = () => {
  const [input, setInput] = useState('');
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);  // Minimized by default on mobile
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialMessageVisible, setInitialMessageVisible] = useState(false);
  const dispatch = useDispatch();
  const { messages, loading } = useSelector((state) => state.chat);
  const messagesEndRef = useRef(null);

  // Load chat history from localStorage
  useEffect(() => {
    const savedMessages = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedMessages) {
      dispatch(setMessages(JSON.parse(savedMessages)));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Mobile view
        setIsOpen(false);  // Minimized by default
      } else {
        setIsOpen(true);
      }
    };

    handleResize(); // Set initial state based on screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialMessageVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    // Save chat history to localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(messages));
  }, [messages, loading]);

  const handleSend = (text) => {
    if (text.trim()) {
      const newMessage = { role: 'user', content: text };
      dispatch(addMessage(newMessage));
      dispatch(sendMessage({ message: text, messages: [...messages, newMessage] }));
      setInput('');
    }
  };

  const handleClearChat = () => {
    dispatch(clearMessages());
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const initialMessage = 'Olen Rahoituskanavan tekoälyapuri Rahoitusluotsi. Kuinka voin auttaa sinua tänään? Voit myös keskustella kanssani muista mieltä askarruttavista asioista.';

  useEffect(() => {
    console.log('Chatbot initialMessage:', initialMessage);
  }, [initialMessage]);

  return (
    <Box className={`chatbot ${isOpen ? 'open' : 'closed'} ${isExpanded ? 'expanded' : ''}`}>
      <Box className="chatbot-header" onClick={toggleOpen}>
        <Typography variant="h6">Rahoitusluotsi</Typography>
        <Box className="chatbot-controls">
          <IconButton onClick={toggleExpand} size="small" sx={{ color: 'white' }}>
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <IconButton onClick={toggleOpen} size="small" sx={{ color: 'white' }}>
            {isOpen ? '-' : '+'}
          </IconButton>
          <IconButton onClick={handleClearChat} size="small" sx={{ color: 'white' }}>
            <Clear />
          </IconButton>
        </Box>
      </Box>
      {isOpen && (
        <Box className="chatbot-body">
          <Box className="chat-messages">
            {initialMessageVisible && (
              <>
                <Box className="message assistant">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{initialMessage}</ReactMarkdown>
                </Box>
                <Box className="quick-replies">
                  <Button variant="contained" color="primary" size="small" onClick={() => handleSend("Haluan kilpailuttaa yrityslainan")}>
                    Haluan kilpailuttaa yrityslainan
                  </Button>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleSend("Haluan kilpailuttaa laskurahoituksen")}>
                    Haluan kilpailuttaa laskurahoituksen
                  </Button>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleSend("Haluan yhteyden asiakaspalveluun")}>
                    Haluan yhteyden asiakaspalveluun
                  </Button>
                </Box>
              </>
            )}
            {messages.map((msg, index) => (
              <Box key={index} className={`message ${msg.role}`}>
                {msg.role === 'assistant' ? (
                  <TypewriterMessage content={msg.content} />
                ) : (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>
                )}
              </Box>
            ))}
            {loading && (
              <Box className="message assistant" sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} />
                <Typography variant="body2" sx={{ ml: 1 }}>Luotsi miettii vastausta..</Typography>
              </Box>
            )}
            <div ref={messagesEndRef} />
          </Box>
          <Box className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSend(input)}
              placeholder="Kirjoita viestisi..."
            />
            <button onClick={() => handleSend(input)}>Send</button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Chatbot;
