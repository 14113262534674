// DetailedInfoModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Grid, TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { saveOffer, rejectOffer } from '../features/offers/offerActions';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const DetailedInfoModal = ({ open, onClose, application, isLoan, editMode, offer }) => {
  const dispatch = useDispatch();
  const initialOfferState = offer || {
    financialInstitutionId: '',
    amount: '',
    loanLenght: '',
    offerAcceptanceInformation: '',
    offerAcceptanceLink: '',
    offerStatus: 0,
  };
  const [offerDetails, setOfferDetails] = useState(initialOfferState);

  useEffect(() => {
    if (offer) {
      setOfferDetails(offer);
    }
  }, [offer]);

  if (!application) return null;

  const handleOfferChange = (e) => {
    const { name, value } = e.target;
    setOfferDetails({ ...offerDetails, [name]: value });
  };

  const saveOfferHandler = () => {
    const offer = {
      id: offerDetails.id || '00000000-0000-0000-0000-000000000000',
      financialInstitutionId: '00000000-0000-0000-0000-000000000000',
      ...(isLoan ? { amount: offerDetails.amount } : { estimatedAmount: offerDetails.amount }),
      loanLenght: isLoan ? offerDetails.loanLenght : 0,
      offerAcceptanceInformation: offerDetails.offerAcceptanceInformation,
      offerAcceptanceLink: offerDetails.offerAcceptanceLink,
      offerStatus: 1,
      [`${isLoan ? 'companyLoanApplicationId' : 'invoiceFundingApplicationId'}`]: application.id,
    };

    dispatch(saveOffer({ offer, isLoan }));
    onClose();
  };

  const rejectApplicationHandler = () => {
    const offer = {
      id: offerDetails.id || '00000000-0000-0000-0000-000000000000',
      financialInstitutionId: '00000000-0000-0000-0000-000000000000',
      [`${isLoan ? 'amount' : 'estimatedAmount'}`]: 0.00,
      offerAcceptanceInformation: offerDetails.offerAcceptanceInformation,
      offerAcceptanceLink: offerDetails.offerAcceptanceLink,
      loanLength: 0,
      offerStatus: 2,
      [`${isLoan ? 'companyLoanApplicationId' : 'invoiceFundingApplicationId'}`]: application.id,
    };

    dispatch(rejectOffer({ offer, isLoan }));
    onClose();
  };

  const fields = isLoan ? {
    'Amount': application.amount?.toLocaleString(),
    'Loan Length': application.loanLenght,
    'Application Status': application.applicationStatusEnum,
    'Created Date': application.createdDate ? new Date(application.createdDate).toLocaleString('fi-FI') : '',
    'Close Date': application.closeDate ? new Date(application.closeDate).toLocaleString('fi-FI') : '',
    'Replacing Existing Loan': application.replacingExistingLoan ? 'Yes' : 'No',
  } : {
    'Estimated Amount': application.estimatedAmount?.toLocaleString(),
    'Application Status': application.applicationStatusEnum,
    'Created Date': application.createdDate ? new Date(application.createdDate).toLocaleString('fi-FI') : '',
    'Close Date': application.closeDate ? new Date(application.closeDate).toLocaleString('fi-FI') : '',
    'Information': application.information,
    'Will Replace Existing': application.willReplaceExisting ? 'Yes' : 'No',
  };

  const companyFields = application.company ? {
    'Company Name': application.company.companyName,
    'BIC': application.company.bic,
    'Email': application.company.email,
    'Phone': application.company.phone,
    'Contact Name': application.company.contactName,
    'Contact Position': application.company.contactPositon,
    'Website': application.company.website,
    'Address': application.company.address,
    'Zipcode': application.company.zipcode,
    'City': application.company.city,
    'Country': application.company.country,
    'Sector': application.company.sector,
    'Company Form': application.company.companyForm,
    'Registration Date': application.company.registrationDate ? new Date(application.company.registrationDate).toLocaleString('fi-FI') : '',
  } : {};

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6">Application Details</Typography>
        <Grid container spacing={2}>
          {Object.keys(fields).map((key) => (
            <Grid item xs={6} key={key}>
              <Typography variant="subtitle2">{key}:</Typography>
              <Typography variant="body2">{fields[key]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{ mt: 2 }}>Company Details</Typography>
        <Grid container spacing={2}>
          {Object.keys(companyFields).map((key) => (
            <Grid item xs={6} key={key}>
              <Typography variant="subtitle2">{key}:</Typography>
              <Typography variant="body2">{companyFields[key]}</Typography>
            </Grid>
          ))}
        </Grid>
        <Typography variant="h6" sx={{ mt: 2 }}>Offers</Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={saveOfferHandler}>Save Offer</Button>
        <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={rejectApplicationHandler}>Reject Application</Button>
        <Box sx={{ mt: 2 }}>
          <TextField
            name="amount"
            label="Amount"
            value={offerDetails.amount}
            onChange={handleOfferChange}
            fullWidth
            margin="normal"
          />
          {isLoan && (
            <TextField
              name="loanLenght"
              label="Loan Length (months)"
              value={offerDetails.loanLenght}
              onChange={handleOfferChange}
              fullWidth
              margin="normal"
            />
          )}
          <TextField
            name="offerAcceptanceInformation"
            label="Offer Acceptance Information"
            value={offerDetails.offerAcceptanceInformation}
            onChange={handleOfferChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="offerAcceptanceLink"
            label="Offer Acceptance Link"
            value={offerDetails.offerAcceptanceLink}
            onChange={handleOfferChange}
            fullWidth
            margin="normal"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailedInfoModal;
