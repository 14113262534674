const blogs = [
    {
        id: 1,
        title: 'Yrityslainan kilpailutus ja yrityslainojen vertailu',
        excerpt: 'Lue, miten yrityslaina kilpailutus ja vaihtoehtojen vertailu auttaa löytämään parhaan rahoituksen yrityksellesi.',
        date: '2024-05-20',
        author: 'Rahoituskanava',
        image: '../assets/blogs/image1.jpg',
        url: 'yrityslaina-kilpailutus-vertailu',
        content: 'Blog1Content'
    },
    {
        id: 2,
        title: 'Oikean laskurahoituksen kilpailutus ja laskurahoituksen vertailu',
        excerpt: 'Opi, kuinka laskurahoitus kilpailutus ja vertailu voi optimoida yrityksen kassavirran mahdollisimman edullisesti.',
        date: '2024-05-20',
        author: 'Rahoituskanava',
        image: '../assets/blogs/laskurahoitus_optimized.webp',
        url: 'laskurahoitus-kilpailutus-vertailu',
        content: 'Blog2Content'
    },
    {
        id: 3,
        title: 'Mitä on laskurahoitus eli factoring?',
        excerpt: 'Laskurahoitus (factoring) on yrityksen myyntisaatavien myynti rahoitusyhtiölle, joka maksaa yritykselle etukäteen osan laskujen arvosta. Tämä parantaa yrityksen kassavirtaa ja vähentää taloudellisia riskejä, mutta siihen liittyy myös kustannuksia ja sopimusehtoja, jotka on syytä ottaa huomioon.',
        date: '2024-05-30',
        author: 'Rahoituskanava',
        image: '../assets/blogs/laskurahoitus.webp',
        url: 'mita-on-laskurahoitus',
        content: 'Blog3Content'
    },
    {
        id: 4,
        title: 'Kuinka valita paras yrityslaina tai laskurahoitus yrityksellesi – Vinkkejä ja asiantuntijan neuvoja',
        excerpt: 'Lue asiantuntijan vinkit ja ohjeet, kuinka valita paras yrityslaina tai laskurahoitus yrityksellesi. Selvitä yrityslainan ja laskurahoituksen erot, vertailu ja kilpailutus.',
        date: '2024-06-10',
        author: 'Rahoituskanava',
        image: '../assets/blogs/blog4.webp',
        url: 'valitse-paras-yrityslaina-laskurahoitus',
        content: 'Blog4Content'
    },
    {
        id: 5,
        title: 'Arvonlisäveron muutos syyskuussa 2024 - Mitä on tapahtumassa?',
        excerpt: 'Syyskuun 1. päivä 2024 alkaen yleinen arvonlisäverokanta Suomessa nousee. Vanha kanta on 24 prosenttia ja uusi yleinen arvonlisäverokanta on 25,5 prosenttia. Tämä muutos vaikuttaa moniin kulutustuotteisiin ja palveluihin, nostamalla niiden hintaa. Tämä veronkorotus voi olla merkittävä taloudellinen taakka yrityksille, jotka joutuvat sopeuttamaan toimintansa uusiin kustannuksiin.',
        date: '2024-08-16',
        author: 'Rahoituskanava',
        image: '../assets/blogs/blog5.webp',
        url: 'arvonlisavero-muutos-vaikutukset',
        content: 'Blog5Content'
    },
];

export default blogs;
