// chatSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../constants';

export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async ({ message, messages }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/api/Rahoitusluotsi/GetResponse`, {
        question: message,
        messages: messages,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [],
    loading: false,
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.messages.push({
          role: 'assistant',
          content: action.payload,
        });
        state.loading = false;
      })
      .addCase(sendMessage.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { addMessage, clearMessages, setMessages } = chatSlice.actions;
export default chatSlice.reducer;
