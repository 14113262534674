import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image

function Kayttoehdot() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, // Ensure the content fills available space
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', // Ensure the content fills available space
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Rahoituskanava.fi käyttöehdot
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >

päivitetty 31.05.2024
<br/><br/>
<h2>Käyttöehdot</h2>
    <p>
        Tervetuloa käyttämään Pohjolan Rahoitusvertailu Oy:n (jäljempänä "Pohjolan Rahoitusvertailu") verkkopalvelua. Käyttämällä tätä verkkopalvelua hyväksyt seuraavat käyttöehdot. Mikäli et hyväksy näitä ehtoja, pyydämme sinua olemaan käyttämättä palveluamme.
    </p>

    <h3>Palvelun käyttö</h3>
    <p>
        Verkkopalvelu on tarkoitettu auttamaan käyttäjiä löytämään ja vertailemaan erilaisia rahoitusratkaisuja. Pohjolan Rahoitusvertailu tarjoaa tietoa rahoitusvaihtoehdoista, mutta ei suoraan tarjoa rahoituspalveluita.
    </p>
    <p>
        Palvelun käyttäjä hyväksyy, että pankkit ja rahoitusyhtiöt voivat olla yhteydessä myös suoraan rahoituksen hakijaan tarjotessaan rahoitusratkaisua tai pyytäessään lisätietoja rahoituksen tarpeen tai yhtiön luottokelpoisuuden selvittämiseksi. Palvelun käyttäjä hyväksyy, että 
        pankit ja rahoitusyhtiöt voivat hakea tietoja kulloinkin vallitsevan luottopolitiikkansa mukaisesti yhtiöstä ja yhtiön edustajasta Suomen Asiakastiedon ja muiden luottotietoyhtiöiden kaikille avoimista ja yksityisistä rekistereistä ja järjestelmistä, kuten Suomen Asiakastiedon muiden yritysluottojen ja taloudellisen tilanteen selvittämistä 
        varten perustetusta järjestelmästä. 
    </p>

    <h3>Vastuunrajoitus</h3>
    <p>
        Pohjolan Rahoitusvertailu ei takaa verkkopalvelussa tarjottujen tietojen täydellisyyttä, ajantasaisuutta tai tarkkuutta. Verkkopalvelun sisältö on tarkoitettu ainoastaan informatiivisiin tarkoituksiin, eikä se muodosta oikeudellista, taloudellista tai muuta ammatillista neuvontaa.
    </p>
    <p>
        Pohjolan Rahoitusvertailu ei ole vastuussa mistään välittömistä, välillisistä, satunnaisista, erityisistä tai seurannaisvahingoista, jotka johtuvat verkkopalvelun käytöstä tai siihen liittyvästä toiminnasta, vaikka Pohjolan Rahoitusvertailulle olisi ilmoitettu tällaisten vahinkojen mahdollisuudesta.
    </p>
    <p>
        Pohjolan Rahoitusvertailu ei ole vastuussa siitä, minkä rahoituspalvelun käyttäjä valitsee verkkopalvelussa esitettyjen tietojen perusteella. Käyttäjä on itse vastuussa kaikista päätöksistään ja toimistaan, jotka perustuvat verkkopalvelun kautta saatuihin tietoihin.
    </p>
    <p>
        Pohjolan Rahoitusvertailu ei ole vastuussa mistään mahdollisista tappioista, jotka aiheutuvat palvelun kautta valitun tai suositellun rahoitusyhtiön suorasta tai epäsuorasta toiminnasta.
    </p>

    <h3>Käyttäjän vastuut</h3>
    <p>
        Käyttäjä sitoutuu käyttämään verkkopalvelua lain ja hyvien tapojen mukaisesti. Käyttäjä ei saa käyttää palvelua laittomaan tai sopimattomaan tarkoitukseen, eikä häiritä palvelun toimintaa millään tavalla.
    </p>

    <h3>Henkilötietojen käsittely</h3>
    <p>
        Henkilötietojen käsittelyä koskevat tiedot löytyvät erillisestä tietosuojaselosteesta, joka on saatavilla verkkopalvelussa.
    </p>

    <h3>Muutokset käyttöehtoihin</h3>
    <p>
        Pohjolan Rahoitusvertailu pidättää oikeuden muuttaa näitä käyttöehtoja milloin tahansa ilman ennakkoilmoitusta. Käyttöehtoihin tehdyt muutokset tulevat voimaan, kun ne on julkaistu verkkopalvelussa.
    </p>

    <h3>Sovellettava laki ja riitojen ratkaisu</h3>
    <p>
        Näihin käyttöehtoihin sovelletaan Suomen lakia. Kaikki näihin käyttöehtoihin tai verkkopalvelun käyttöön liittyvät erimielisyydet ratkaistaan ensisijaisesti neuvotteluin. Mikäli neuvottelut eivät johda sopimukseen, riidat ratkaistaan Helsingin käräjäoikeudessa.
    </p>

    <h3>Yhteystiedot</h3>
    <p>
        Mikäli sinulla on kysyttävää näistä käyttöehdoista, voit ottaa meihin yhteyttä sähköpostitse osoitteeseen info@rahoituskanava.fi.
    </p>
             </Typography>               
            </Container>
        </Box>
    );
}

export default Kayttoehdot;
