import { createSlice } from '@reduxjs/toolkit';
import { saveOffer, rejectOffer } from '../features/offers/offerActions';
import { fetchCompanyLoanApplications } from '../features/companyLoanApplications/companyLoanApplicationsActions';
import { fetchInvoiceFundingApplications } from '../features/invoiceFundingApplications/invoiceFundingApplicationsActions';

const initialState = {
  loading: false,
  error: '',
};

const findAndUpdateOffer = (applications, offer, isLoan) => {
  const applicationKey = isLoan ? 'companyLoanApplicationId' : 'invoiceFundingApplicationId';
  return applications.map((application) => {
    if (application.id === offer[applicationKey]) {
      const updatedOffers = application.offers.map((existingOffer) =>
        existingOffer.id === offer.id ? offer : existingOffer
      );
      return { ...application, offers: updatedOffers };
    }
    return application;
  });
};

const offerSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveOffer.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(saveOffer.fulfilled, (state, action) => {
        const { offer, isLoan } = action.payload;
        if (isLoan) {
          state.companyLoanApplications.applications = findAndUpdateOffer(
            state.companyLoanApplications.applications,
            offer,
            true
          );
        } else {
          state.invoiceFundingApplications.applications = findAndUpdateOffer(
            state.invoiceFundingApplications.applications,
            offer,
            false
          );
        }
        state.loading = false;
      })
      .addCase(saveOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(rejectOffer.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(rejectOffer.fulfilled, (state, action) => {
        const { offer, isLoan } = action.payload;
        if (isLoan) {
          state.companyLoanApplications.applications = findAndUpdateOffer(
            state.companyLoanApplications.applications,
            offer,
            true
          );
        } else {
          state.invoiceFundingApplications.applications = findAndUpdateOffer(
            state.invoiceFundingApplications.applications,
            offer,
            false
          );
        }
        state.loading = false;
      })
      .addCase(rejectOffer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCompanyLoanApplications.fulfilled, (state, action) => {
        state.companyLoanApplications = { applications: action.payload };
      })
      .addCase(fetchInvoiceFundingApplications.fulfilled, (state, action) => {
        state.invoiceFundingApplications = { applications: action.payload };
      });
  },
});

export default offerSlice.reducer;
