// src/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Container, Link, Button, Grid, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import blogs from './blogs/blogsData'; // Import the blogs data

function Home() {
    // Get the 2 latest blogs
    const latestBlogs = blogs
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 2);

    return (
        <>
            <Helmet>
                <title>Yritysrahoituksen kilpailutus | Rahoituskanava</title>
                <meta name="description" content="Rahoituskanava on uusi kilpailutuspalvelu yritykselle rahoituksen hankintaan. Saat monta tarjousta yhdellä hakemuksella." />
                <meta name="keywords" content="yritysrahoitus, kilpailutus, yrityslaina, laskurahoitus, Rahoituskanava" />
                <style>{`
                    body {
                        font-family: 'Lato', sans-serif;
                    }
                `}</style>
            </Helmet>

            <header>
                {/* Hero Section */}
                <Box
                    sx={{
                        backgroundImage: `url(${heroImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100vh', // Make sure it covers full height
                        position: 'relative',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                        }}
                    />
                    <Container
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            color: 'white',
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            padding: { xs: '20px', md: '40px' },
                        }}
                    >
                        <Typography
                            variant="h1"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '2.5rem', md: '4rem' },
                                fontWeight: 'bold',
                                fontFamily: 'Lato, sans-serif',
                                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            Yritysrahoituksen kilpailutus
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: { xs: '1.2rem', md: '1.5rem' },
                                fontFamily: 'Lato, sans-serif',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            Rahoituskanava auttaa yritystäsi löytämään parhaat rahoitusratkaisut ilman kuluja ja sitoumuksetta. Vertaile ja kilpailuta yrityksen rahoitusratkaisut helposti ja nopeasti. 
                        </Typography>
                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/yrityslaina"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#DAA520', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                endIcon={<ArrowForwardIcon />}
                                aria-label="Go to Business Loan page"
                            >
                                Yrityslaina
                            </Button>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/laskurahoitus"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#DAA520', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                endIcon={<ArrowForwardIcon />}
                                aria-label="Go to Invoice Financing page"
                            >
                                Laskurahoitus
                            </Button>
                        </Box>

                        {/* New section with stars and text */}
                        <Box sx={{ mt: 4, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 1 }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                                    fontFamily: 'Lato, sans-serif',
                                    fontWeight: 'bold',
                                    mb: 2,
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                }}
                            >
                                {[...Array(5)].map((_, index) => (
                                    <StarIcon key={index} sx={{ color: '#FFD700' }} /> // Gold stars
                                ))}{' '}
                                Viiden tähden kilpailutuspalvelu käytössäsi ilmaiseksi ja sitoumuksetta
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: 'Lato, sans-serif',
                                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                    mb: 2,
                                }}
                            >
                                Yhdellä hakemuksella monta tarjousta, joista voit valita yritykselle sopivimman.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: 'Lato, sans-serif',
                                    fontSize: { xs: '1.2rem', md: '1.5rem' },
                                    fontWeight: 'bold',
                                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                    mb: 2,
                                    wordBreak: 'break-word', // Ensure long words wrap to the next line
                                    whiteSpace: 'normal', // Ensure text wraps to the next line
                                }}
                            >
                                Rahoitusten vertailu nopeasti ja helposti. Kilpailuta{' '}
                                <Link
                                    component={RouterLink}
                                    to="/yrityslaina"
                                    underline="hover"
                                    color="primary"
                                    sx={{
                                        fontFamily: 'Lato, sans-serif',
                                        fontSize: { xs: '1.2rem', md: '1.5rem' },
                                        fontWeight: 'bold',
                                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                        mb: 2,
                                    }}
                                >
                                    yrityslaina
                                </Link>{' '}
                                ja{' '}
                                <Link
                                    component={RouterLink}
                                    to="/laskurahoitus"
                                    underline="hover"
                                    color="primary"
                                    sx={{
                                        fontFamily: 'Lato, sans-serif',
                                        fontSize: { xs: '1.2rem', md: '1.5rem' },
                                        fontWeight: 'bold',
                                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                        mb: 2,
                                    }}
                                >
                                    laskurahoituspalvelut
                                </Link>{' '}
                                kätevästi Rahoituskanavassa.
                            </Typography>
                        </Box>
                    </Container>
                   
                    

                </Box>
            </header>

            <main>
                {/* Transparent Section */}

                <Container sx={{ mt: 8, p: 2 }}>
                <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                            fontWeight: 'bold',
                            fontFamily: 'Lato, sans-serif',
                            mb: 4,
                            textAlign: 'center',
                        }}
                    >
                         {' '}Asiantuntijamme vinkit yritysrahoituksen kilpailutukseen
                    </Typography>
<Grid container spacing={4}>
    
    
                          

                            

                            <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                            Lue tästä, miten valmistaudut yrityslainan kilpailutukseen ja tarjousten vertailuun<br/><br/>
                                    <Button
                                variant="contained"
                                component={RouterLink}
                                to="/yrityslaina/miten-valmistautua-kilpailutukseen"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#122652', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                
                                aria-label="yrityslaina vinkit"                            >
                                Lue vinkit
                            </Button>

                           </Box></Grid>

                           <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                            Lue tästä ohjeistus laskurahoituksen kilpailutukseen ja tarjousten vertailuun<br/><br/>
                                    <Button
                                variant="contained"
                                component={RouterLink}
                                to="/laskurahoitus/miten-valmistautua-kilpailutukseen"
                                sx={{
                                    textTransform: 'none',
                                    fontFamily: 'Lato, sans-serif',
                                    padding: '10px 20px',
                                    backgroundColor: '#122652', // Gold button color
                                    '&:hover': {
                                        backgroundColor: '#DAA520', // Darker gold on hover
                                    },
                                }}
                                
                                aria-label="yrityslaina vinkit"                            >
                                Lue vinkit
                            </Button>

                           </Box></Grid>

                           
                            



</Grid>
</Container>



                <Container sx={{ mt: 8, p: 2 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                            fontWeight: 'bold',
                            fontFamily: 'Lato, sans-serif',
                            mb: 4,
                            textAlign: 'center',
                        }}
                    >
                         {' '}Viimeisimmät artikkelit
                    </Typography>
                    <Grid container spacing={4}>
                        {latestBlogs.map((blog) => (
                            <Grid item xs={12} md={6} key={blog.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={blog.image}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Lato, sans-serif' }}>
                                            {blog.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Lato, sans-serif' }}>
                                            {blog.excerpt}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button component={RouterLink} to={`/blogs/${blog.url}`} size="small" color="primary" sx={{ fontFamily: 'Lato, sans-serif' }}>
                                            Lue lisää
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>

            <footer>
                {/* Add a footer if necessary */}
            </footer>
        </>
    );
}

export default Home;
