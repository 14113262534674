import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Tabs,
    Tab,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import { API_URL } from './constants';
import AdminEditApplicationModal from './adminEditApplicationModal';
import FinancialInstitutionsComponent from './FinancialInstitutionsComponent';
import CompanyLoanApplicationsComponent from './CompanyLoanApplicationsComponent';
import InvoiceFundingApplicationsComponent from './InvoiceFundingApplicationsComponent';

function AdminDashboard() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = localStorage.getItem('token');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [loanApplications, setLoanApplications] = useState([]);
    const [invoiceFundingApplications, setInvoiceFundingApplications] = useState([]);
    const [tab, setTab] = useState(0);
    const [subTab, setSubTab] = useState(0);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [statusFilter, setStatusFilter] = useState('');
    const [showWithOffers, setShowWithOffers] = useState(false);
    const [showCancelled, setShowCancelled] = useState('all'); // new state for filtering by offer status
    const [searchQuery, setSearchQuery] = useState(''); // new state for free text search
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (token) {
            fetchApplications();
        }
    }, [token, startDate, endDate, statusFilter, showWithOffers, showCancelled, searchQuery]);

    const fetchApplications = async () => {
        try {
            const loanResponse = await fetch(`${API_URL}api/CompanyLoanApplications/UserLoanApplications`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (loanResponse.ok) {
                const loanData = await loanResponse.json();
                setLoanApplications(filterApplications(loanData));
            } else {
                console.error('Error fetching loan applications:', loanResponse.statusText);
            }

            const invoiceResponse = await fetch(`${API_URL}api/InvoiceFundingApplications/UserInvoiceFundingApplications`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (invoiceResponse.ok) {
                const invoiceData = await invoiceResponse.json();
                setInvoiceFundingApplications(filterApplications(invoiceData));
            } else {
                console.error('Error fetching invoice funding applications:', invoiceResponse.statusText);
            }
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const filterApplications = (applications) => {
        return applications.filter((app) => {
            const appDate = new Date(app.createdDate).toISOString().split('T')[0];
            const dateInRange = appDate >= startDate && appDate <= endDate;
            const statusMatches = statusFilter ? app.applicationStatusEnum === statusFilter : true;
            const offersMatch = showWithOffers ? (app.offers && app.offers.length > 0) : true;
            const showCancelledMatch = showCancelled === 'all' ||
                (showCancelled === 'active' && app.applicationStatusEnum !== 'DeletedApplication') ||
                (showCancelled === 'cancelled' && app.applicationStatusEnum === 'DeletedApplication');
            const searchMatch = app.company?.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                app.amount?.toString().includes(searchQuery) ||
                app.estimatedAmount?.toString().includes(searchQuery);
            return dateInRange && statusMatches && offersMatch && showCancelledMatch && searchMatch;
        });
    };

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const sortedData = [...(subTab === 0 ? loanApplications : invoiceFundingApplications)].sort((a, b) => {
            if (a[property] < b[property]) return isAsc ? -1 : 1;
            if (a[property] > b[property]) return isAsc ? 1 : -1;
            return 0;
        });

        subTab === 0 ? setLoanApplications(sortedData) : setInvoiceFundingApplications(sortedData);
    };

    const handlePeruutettu = async (application) => {
        try {
            const endpoint = `${API_URL}api/CompanyLoanApplications/${application.id}`;
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...application, applicationStatusEnum: 'DeletedApplication' })
            });

            if (response.ok) {
                fetchApplications();
            } else {
                console.error('Error cancelling application:', response.statusText);
            }
        } catch (error) {
            console.error('Error cancelling application:', error);
        }
    };

    const handleSetActive = async (application) => {
        try {
            const endpoint = `${API_URL}api/CompanyLoanApplications/${application.id}`;
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...application, applicationStatusEnum: 'NewApplication' })
            });

            if (response.ok) {
                fetchApplications();
            } else {
                console.error('Error setting application as active:', response.statusText);
            }
        } catch (error) {
            console.error('Error setting application as active:', error);
        }
    };

    const getRowStyle = (status) => {
        switch (status) {
            case 'InProcessApplication':
                return { backgroundColor: '#a3d6a7', color: 'black' }; // Light Green
            case 'DeletedApplication':
                return { backgroundColor: 'lightgray' };
            default:
                return {};
        }
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleSubTabChange = (event, newValue) => {
        setSubTab(newValue);
    };

    const handleRefresh = () => {
        fetchApplications();
    };

    const handleEditClick = (application) => {
        setSelectedApplication(application);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedApplication(null);
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
                    padding: { xs: 2, md: 4 },
                    borderRadius: 2,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    width: '100%',
                    textAlign: 'left',
                    color: '#333333', // Dark text color
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    mt: { xs: 8, md: 8 }, // Add top margin for mobile view
                }}
            >
                <IconButton onClick={handleRefresh} aria-label="refresh">
                    <RefreshIcon />
                </IconButton>
                <Tabs value={tab} onChange={handleTabChange} aria-label="Admin Dashboard Tabs">
                    <Tab label="Loan applications" />
                    <Tab label="Brokers" />
                </Tabs>
                {tab === 0 && (
                    <>
                        <Tabs value={subTab} onChange={handleSubTabChange} aria-label="Sub Tabs">
                            <Tab label="Company Loan Applications" />
                            <Tab label="Invoice Funding Applications" />
                        </Tabs>
                        <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
                            <TextField
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="End Date"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                            <Select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Status Filter' }}
                            >
                                <MenuItem value="">
                                    <em>All Statuses</em>
                                </MenuItem>
                                <MenuItem value="NewApplication">Uusi</MenuItem>
                                <MenuItem value="InProcessApplication">Kilpailutuksessa</MenuItem>
                                <MenuItem value="ClosedApplication">Valmis</MenuItem>
                                <MenuItem value="DeletedApplication">Peruutettu</MenuItem>
                            </Select>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showWithOffers}
                                        onChange={(e) => setShowWithOffers(e.target.checked)}
                                        name="showWithOffers"
                                        color="primary"
                                    />
                                }
                                label="Show with Offers"
                            />
                            <Select
                                value={showCancelled}
                                onChange={(e) => setShowCancelled(e.target.value)}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Cancelled Filter' }}
                            >
                                <MenuItem value="all">All Offers</MenuItem>
                                <MenuItem value="active">Active Offers</MenuItem>
                                <MenuItem value="cancelled">Cancelled Offers</MenuItem>
                            </Select>
                            <TextField
                                label="Search"
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        {subTab === 0 ? (
                            <CompanyLoanApplicationsComponent
                                applications={loanApplications}
                                order={order}
                                orderBy={orderBy}
                                handleSort={handleSort}
                                handleEditClick={handleEditClick}
                                handlePeruutettu={handlePeruutettu}
                                handleSetActive={handleSetActive}
                            />
                        ) : (
                            <InvoiceFundingApplicationsComponent
                                applications={invoiceFundingApplications}
                                order={order}
                                orderBy={orderBy}
                                handleSort={handleSort}
                                handleEditClick={handleEditClick}
                                handlePeruutettu={handlePeruutettu}
                                handleSetActive={handleSetActive}
                            />
                        )}
                    </>
                )}
                {tab === 1 && <FinancialInstitutionsComponent />}
                {selectedApplication && (
                    <AdminEditApplicationModal
                        open={modalOpen}
                        onClose={handleCloseModal}
                        application={selectedApplication}
                    />
                )}
            </Container>
        </Box>
    );
}

export default AdminDashboard;
