import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import { Helmet } from 'react-helmet';

function KiitosYrityslainahakemus() {
    return (
        <>        <Helmet>
        <title>Kilpailutus aloitettu | Yrityslaina | Rahoituskanava</title>
    </Helmet>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, // Ensure the content fills available space
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', // Ensure the content fills available space
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Yrityslainojen kilpailutus aloitettu
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >

                <p>Kiitos hakemuksesta! </p>
                <p>Voit nyt ottaa rennosti hetken ja varautua mahdollisiin tarkentaviin kysymyksiin pankeilta ja rahoitusyhtiöltä yhtiöstäsi ja rahoitustarpeesta.</p>
                <p>Ilmoitamme uusista tarjouksista sähköpostitse, löydät  uudet yrityslainatarjoukset myös palvelumme omista sivuista.</p>


             </Typography>               
            </Container>
        </Box>
        </>

    );
}

export default KiitosYrityslainahakemus;
