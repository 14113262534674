// src/reducers/authReducer.js

import { createSlice } from '@reduxjs/toolkit';
import { login, logout } from '../features/auth/authSlice';

const initialState = {
  loading: false,
  isAuthenticated: !!localStorage.getItem('token'),
  isUser: false, 
  isAdmin: false, 
  isBroker: false,
  user: localStorage.getItem('token') ? JSON.parse(atob(localStorage.getItem('token').split('.')[1])) : null,
  error: '',
};

// Helper function to get the role from the token payload
const getRoleFromToken = (token) => {
  if (!token) return null;
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    ...initialState,
    // Set the role flags based on the token stored in localStorage
    ...(() => {
      const role = getRoleFromToken(localStorage.getItem('token'));
      return {
        isUser: role === 'User',
        isAdmin: role === 'Admin',
        isBroker: role === 'Broker',
      };
    })(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.user;

        // Reset roles
        state.isUser = false; 
        state.isAdmin = false; 
        state.isBroker = false;

        // Determine the role and set the appropriate flag
        const role = action.payload.user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        if (role === 'User') {
          state.isUser = true;
        } else if (role === 'Admin') {
          state.isAdmin = true;
        } else if (role === 'Broker') {
          state.isBroker = true;
        }

        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.isUser = false; 
        state.isAdmin = false;
        state.isBroker = false;        
        state.user = null;
      });
  },
});

export default authSlice.reducer;
