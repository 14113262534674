import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';

const DataCollectionNotification = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: 'white',
                textAlign: 'center',
                p: 2,
                zIndex: 10000,
            }}
        >
            <Typography variant="body2">
                Keräämme käyttäjätietoja sivustollamme parantaaksemme asiakaskokemusta. Käyttämällä sivustoamme, hyväksyt evästekäytäntömme.
            </Typography>
            <Button
                onClick={handleClose}
                sx={{
                    marginLeft: 2,
                    backgroundColor: 'blue',
                    color: 'white',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: 'darkblue',
                    },
                }}
            >
                Sulje
            </Button>
        </Box>
    );
};

export default DataCollectionNotification;
