// offerActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../constants';

export const saveOffer = createAsyncThunk(
  'offers/saveOffer',
  async ({ offer, isLoan }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const endpoint = `${API_URL}api/${isLoan ? 'CompanyLoanOffers' : 'InvoiceFundingOffers'}`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(offer)
      });

      if (!response.ok) {
        throw new Error('Failed to save offer');
      }

      const data = await response.json();
      return { offer: data, isLoan };
    } catch (error) {
      console.error('Error saving offer:', error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const rejectOffer = createAsyncThunk(
  'offers/rejectOffer',
  async ({ offer, isLoan }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const endpoint = `${API_URL}api/${isLoan ? 'CompanyLoanOffers' : 'InvoiceFundingOffers'}`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(offer)
      });

      if (!response.ok) {
        throw new Error('Failed to reject offer');
      }

      const data = await response.json();
      return { offer: data, isLoan };
    } catch (error) {
      console.error('Error rejecting offer:', error.message);
      return rejectWithValue(error.message);
    }
  }
);
