import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Alert } from '@mui/material';

function ResetPasswordRequest() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage(null);
        setError(null);

        if (!email) {
            setError('Täytä sähköpostikenttä.');
            return;
        }

        try {
            const response = await fetch('https://localhost:44385/api/Users/reset-password-request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Virheellinen sähköposti.');
            }

            setMessage('Salasanan palautuslinkki on lähetetty sähköpostiisi.');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 8,
                    p: 4,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    Palauta Salasana
                </Typography>
                <TextField
                    label="Sähköposti"
                    name="email"
                    type="email"
                    value={email}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    sx={{ mb: 2 }}
                />
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {message && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {message}
                    </Alert>
                )}
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Lähetä palautuslinkki
                </Button>
            </Box>
        </Container>
    );
}

export default ResetPasswordRequest;
