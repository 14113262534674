import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { API_URL } from './constants';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000, // Increase the width of the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '90vh'
};

const AdminEditApplicationModal = ({ open, onClose, application }) => {
    const token = localStorage.getItem('token');
    const [company, setCompany] = useState(application?.company || {});
    const [offers, setOffers] = useState(application?.offers || []);
    const [financialInstitutions, setFinancialInstitutions] = useState([]);
    const [applicationDetails, setApplicationDetails] = useState(application);
    const [originalCompany, setOriginalCompany] = useState({});
    const [originalApplication, setOriginalApplication] = useState({});

    useEffect(() => {
        if (application) {
            setCompany(application.company);
            setOffers(application.offers);
            setApplicationDetails(application);
            setOriginalCompany(application.company);
            setOriginalApplication(application);
        }
    }, [application]);

    useEffect(() => {
        const fetchFinancialInstitutions = async () => {
            const response = await fetch(`${API_URL}api/FinancialInstitutions`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setFinancialInstitutions(data);
        };
        fetchFinancialInstitutions();
    }, []);

    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setCompany({ ...company, [name]: value });
    };

    const handleApplicationChange = (e) => {
        const { name, value } = e.target;
        setApplicationDetails({ ...applicationDetails, [name]: value });
    };

    const handleOfferChange = (index, e) => {
        const { name, value } = e.target;
        const updatedOffers = offers.map((offer, i) => (i === index ? { ...offer, [name]: value } : offer));
        setOffers(updatedOffers);
    };

    const saveCompany = async () => {
        const response = await fetch(`${API_URL}api/Companies/${company.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(company),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        setOriginalCompany(company);
    };

    const cancelCompanyChanges = () => {
        setCompany(originalCompany);
    };

    const saveApplication = async () => {
        const response = await fetch(`${API_URL}api/CompanyLoanApplications/${applicationDetails.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(applicationDetails),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        setOriginalApplication(applicationDetails);
    };

    const cancelApplicationChanges = () => {
        setApplicationDetails(originalApplication);
    };

    const saveOffer = async (index) => {
        const offer = offers[index];
        offer.CompanyLoanApplicationId = applicationDetails.id; // Set the CompanyLoanApplicationId
        let response;

        if (offer.id) {
            response = await fetch(`${API_URL}api/CompanyLoanOffers/${offer.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(offer),
            });
        } else {
            response = await fetch(`${API_URL}api/CompanyLoanOffers`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(offer),
            });

            if (response.ok) {
                const savedOffer = await response.json();
                const updatedOffers = [...offers];
                updatedOffers[index] = savedOffer;
                setOffers(updatedOffers);
            }
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    };

    const deleteOffer = async (index) => {
        const offer = offers[index];
        if (offer.id) {
            const response = await fetch(`${API_URL}api/CompanyLoanOffers/${offer.id}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }

        const updatedOffers = offers.filter((_, i) => i !== index);
        setOffers(updatedOffers);
    };

    const handleAddOffer = () => {
        setOffers([...offers, { financialInstitutionId: '', amount: '', loanLenght: '', offerAcceptanceInformation: '', offerAcceptanceLink: '', offerStatus: 0 }]);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="edit-application-modal" aria-describedby="edit-application-modal-description">
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">Edit Company Loan Application</Typography>
                <Box component="form" noValidate autoComplete="off">
                    <Typography variant="subtitle1">Company Details</Typography>
                    <TextField name="companyName" label="Company Name" value={company.companyName} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="phone" label="Phone" value={company.phone} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="email" label="Email" value={company.email} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="address" label="Address" value={company.address} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="zipcode" label="Zipcode" value={company.zipcode} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="city" label="City" value={company.city} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="country" label="Country" value={company.country} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="sector" label="Sector" value={company.sector} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <TextField name="companyForm" label="Company Form" value={company.companyForm} onChange={handleCompanyChange} fullWidth margin="normal" />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={saveCompany} variant="contained" color="primary">Save Company</Button>
                        <Button onClick={cancelCompanyChanges} variant="outlined" color="secondary">Cancel Company Changes</Button>
                    </Box>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Application Details</Typography>
                    <TextField name="amount" label="Amount" value={applicationDetails.amount} onChange={handleApplicationChange} fullWidth margin="normal" />
                    <TextField name="loanLenght" label="Loan Length (months)" value={applicationDetails.loanLenght} onChange={handleApplicationChange} fullWidth margin="normal" />
                    <TextField name="createdDate" label="Created Date" value={applicationDetails.createdDate} onChange={handleApplicationChange} fullWidth margin="normal" />
                    <TextField name="closeDate" label="Close Date" value={applicationDetails.closeDate} onChange={handleApplicationChange} fullWidth margin="normal" />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={saveApplication} variant="contained" color="primary">Save Application</Button>
                        <Button onClick={cancelApplicationChanges} variant="outlined" color="secondary">Cancel Application Changes</Button>
                    </Box>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Offers</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Financial Institution</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Loan Length (months)</TableCell>
                                    <TableCell>Offer Acceptance Information</TableCell>
                                    <TableCell>Offer Acceptance Link</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.map((offer, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <InputLabel>Financial Institution</InputLabel>
                                                <Select
                                                    name="financialInstitutionId"
                                                    value={offer.financialInstitutionId}
                                                    onChange={(e) => handleOfferChange(index, e)}
                                                >
                                                    {financialInstitutions.map((fi) => (
                                                        <MenuItem key={fi.id} value={fi.id}>{fi.companyName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="amount"
                                                value={offer.amount}
                                                onChange={(e) => handleOfferChange(index, e)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="loanLenght"
                                                value={offer.loanLenght}
                                                onChange={(e) => handleOfferChange(index, e)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="offerAcceptanceInformation"
                                                value={offer.offerAcceptanceInformation}
                                                onChange={(e) => handleOfferChange(index, e)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                name="offerAcceptanceLink"
                                                value={offer.offerAcceptanceLink}
                                                onChange={(e) => handleOfferChange(index, e)}
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => deleteOffer(index)}><DeleteIcon /></IconButton>
                                            <Button onClick={() => saveOffer(index)} variant="contained" color="primary" size="small">Save</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button onClick={handleAddOffer} variant="contained" color="primary" sx={{ mt: 2 }}>Add Offer</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AdminEditApplicationModal;
