import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
  Tabs,
  Tab,
  IconButton,
  TextField,
  Select,
  MenuItem
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import BrokerYhteystiedot from './BrokerYhteystiedot';
import heroImage from '../assets/hero-image-digital-finance.jpg';
import DetailedInfoModal from './DetailedInfoModal';
import { fetchCompanyLoanApplications } from '../features/companyLoanApplications/companyLoanApplicationsActions';
import { fetchInvoiceFundingApplications } from '../features/invoiceFundingApplications/invoiceFundingApplicationsActions';
import { saveOffer, rejectOffer } from '../features/offers/offerActions';

const columnLabelsLoanApplications = {
  companyName: { label: 'Company Name', getValue: (row) => row.company?.companyName || 'N/A' },
  bic: { label: 'BIC', getValue: (row) => row.company?.bic || 'N/A' },
  email: { label: 'Email', getValue: (row) => row.company?.email || 'N/A' },
  phone: { label: 'Phone', getValue: (row) => row.company?.phone || 'N/A' },
  amount: { label: 'Määrä (€)', getValue: (row) => row.amount?.toFixed(2).toLocaleString() },
  loanLenght: { label: 'Laina-aika (kk)', getValue: (row) => row.loanLenght || 'ei aikaa' },
  applicationStatusEnum: {
    label: 'Tila',
    getValue: (row) => {
      const statusMapping = {
        'NewApplication': 'Uusi',
        'InProcessApplication': 'Kilpailutuksessa',
        'ClosedApplication': 'Valmis',
        'RejectedApplication': 'Hylätty',
        'OfferedApplication': 'Tarjottu',
        'PaidoutApplication': 'Maksettu'
      };
      const offerStatusMapping = {
        'Pending': 'Uusi',
        'OfferedByLender': 'Tarjous tehty',
        'RejectedByLender': 'Hylätty',
        'DisbursedByLender': 'Hyväksytty'
      };
      if (row.offers && row.offers.length > 0) {
        const offerStatus = row.offers[0].offerStatus;
        return offerStatusMapping[offerStatus] || 'ei tilaa';
      }
      return statusMapping[row.applicationStatusEnum] || 'ei tilaa';
    },
  },
  createdDate: {
    label: 'Luontipäivä',
    getValue: (row) => (row.createdDate ? new Date(row.createdDate).toLocaleString('fi-FI') : 'ei luontia'),
  }
};

const columnLabelsInvoiceFundingApplications = {
  companyName: { label: 'Company Name', getValue: (row) => row.company?.companyName || 'N/A' },
  bic: { label: 'BIC', getValue: (row) => row.company?.bic || 'N/A' },
  email: { label: 'Email', getValue: (row) => row.company?.email || 'N/A' },
  phone: { label: 'Phone', getValue: (row) => row.company?.phone || 'N/A' },
  estimatedAmount: { label: 'Estimated Amount', getValue: (row) => row.estimatedAmount?.toLocaleString() || 'N/A' },
  applicationStatusEnum: {
    label: 'Tila',
    getValue: (row) => {
      const statusMapping = {
        'NewApplication': 'Uusi',
        'InProcessApplication': 'Kilpailutuksessa',
        'ClosedApplication': 'Valmis',
        'RejectedApplication': 'Hylätty',
        'OfferedApplication': 'Tarjottu',
        'PaidoutApplication': 'Maksettu'
      };
      const offerStatusMapping = {
        'Pending': 'Uusi',
        'OfferedByLender': 'Tarjous tehty',
        'RejectedByLender': 'Hylätty',
        'DisbursedByLender': 'Hyväksytty'
      };
      if (row.offers && row.offers.length > 0) {
        const offerStatus = row.offers[0].offerStatus;
        return offerStatusMapping[offerStatus] || 'ei tilaa';
      }
      return statusMapping[row.applicationStatusEnum] || 'ei tilaa';
    },
  },
  createdDate: {
    label: 'Luontipäivä',
    getValue: (row) => (row.createdDate ? new Date(row.createdDate).toLocaleString('fi-FI') : 'ei luontia'),
  }
};

function BrokerDashboard() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const location = useLocation();
  const companyLoanApplications = useSelector((state) => state.companyLoanApplications.applications);
  const invoiceFundingApplications = useSelector((state) => state.invoiceFundingApplications.applications);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [tab, setTab] = useState(0);
  const [statusFilter, setStatusFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoan, setIsLoan] = useState(true);
  const [editMode, setEditMode] = useState(false);
/*
  useEffect(() => {
    if (isAuthenticated) {
      // dispatch(fetchCompanyLoanApplications());
      // dispatch(fetchInvoiceFundingApplications());
    }
  }, [isAuthenticated, dispatch]);
*/
  /*
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab');
    if (activeTab) {
      //setTab(parseInt(activeTab));
    }
  }, [location.search]);
*/
  const handleSort = (property) => {
/*
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...(tab === 0 ? companyLoanApplications : invoiceFundingApplications)].sort((a, b) => {
      if (a[property] < b[property]) return isAsc ? -1 : 1;
      if (a[property] > b[property]) return isAsc ? 1 : -1;
      return 0;
    });

    if (tab === 0) {
      dispatch({ type: 'companyLoanApplications/setSortedApplications', payload: sortedData });
    } else {
      dispatch({ type: 'invoiceFundingApplications/setSortedApplications', payload: sortedData });
    }
  */
  };

  const handleOffer = (application, isLoan) => {
    setSelectedApplication(application);
    setIsLoan(isLoan);
    setEditMode(false);
    setModalOpen(true);
  };

  const handleReject = (application, isLoan) => {
    const offer = {
      [`${isLoan ? 'companyLoanApplicationId' : 'invoiceFundingApplicationId'}`]: application.id,
      amount: application.amount,
      loanLenght: application.loanLenght,
      offerStatus: 'RejectedByLender'
    };
    dispatch(rejectOffer({ offer, isLoan }));
  };

  const handleDisburse = (application, isLoan) => {
    const offer = {
      [`${isLoan ? 'companyLoanApplicationId' : 'invoiceFundingApplicationId'}`]: application.id,
      amount: application.amount,
      loanLenght: application.loanLenght,
      offerStatus: 'DisbursedByLender'
    };
    dispatch(saveOffer({ offer, isLoan }));
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleRefresh = () => {
    dispatch(fetchCompanyLoanApplications());
    dispatch(fetchInvoiceFundingApplications());
  };

  const getRowStyle = (application) => {
    if (application.offers && application.offers.length > 0) {
      const offerStatus = application.offers[0].offerStatus;
      if (offerStatus === 'DisbursedByLender') {
        return { backgroundColor: 'lightblue' }; // Light Blue
      }
      if (offerStatus === 'RejectedByLender') {
        return { backgroundColor: 'lightred' }; // Light Red
      }
      return { backgroundColor: '#a3d6a7' }; // Light Green
    }
    return {};
  };

  const handleRowClick = (application, isLoan) => {
    setSelectedApplication(application);
    setIsLoan(isLoan);
    setEditMode(false);
    setModalOpen(true);
  };

  const handleEditOffer = (application, offer, isLoan) => {
    setSelectedApplication(application);
    setSelectedOffer(offer);
    setIsLoan(isLoan);
    setEditMode(true);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedApplication(null);
    setSelectedOffer(null);
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
        }}
      />
      <Container
        sx={{
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
          padding: { xs: 2, md: 4 },
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
          width: '100%',
          textAlign: 'left',
          color: '#333333', // Dark text color
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          mt: { xs: 8, md: 8 }, // Add top margin for mobile view
        }}
      >
        <IconButton onClick={handleRefresh} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
        <Tabs value={tab} onChange={handleTabChange} aria-label="broker dashboard tabs">
          <Tab label="Loan applications" />
          <Tab label="Contact details and company description" />
        </Tabs>
        {tab === 0 && (
          <>
            <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
              <TextField
                label="Search"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Status Filter' }}
              >
                <MenuItem value="">
                  <em>All Statuses</em>
                </MenuItem>
                <MenuItem value="NewApplication">New</MenuItem>
                <MenuItem value="InProcessApplication">In Process</MenuItem>
                <MenuItem value="ClosedApplication">Closed</MenuItem>
                <MenuItem value="RejectedApplication">Rejected</MenuItem>
                <MenuItem value="OfferedApplication">Offered</MenuItem>
                <MenuItem value="PaidoutApplication">Paid Out</MenuItem>
              </Select>
            </Box>
            <Typography variant="body1" gutterBottom>
              Company Loan Applications
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {Object.keys(columnLabelsLoanApplications).map((key) => (
                      <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                        <TableSortLabel
                          active={orderBy === key}
                          direction={orderBy === key ? order : 'asc'}
                          onClick={() => handleSort(key)}
                        >
                          {columnLabelsLoanApplications[key].label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companyLoanApplications.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow style={getRowStyle(row)} onClick={() => handleRowClick(row, true)}>
                        <TableCell>
                          <IconButton size="small" onClick={() => handleRowClick(row, true)}>
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        {Object.keys(columnLabelsLoanApplications).map((key) => (
                          <TableCell key={`${row.id}-${key}`}>{columnLabelsLoanApplications[key].getValue(row)}</TableCell>
                        ))}
                        <TableCell>
                          {row.applicationStatusEnum === 'NewApplication' && (!row.offers || row.offers.length === 0) && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => handleOffer(row, true)}
                              sx={{ mr: 1 }}
                            >
                              Offer
                            </Button>
                          )}
                          {row.offers && row.offers.length > 0 && (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                color="warning"
                                onClick={() => handleEditOffer(row, row.offers[0], true)}
                                sx={{ mr: 1 }}
                              >
                                Edit Offer
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                onClick={() => handleDisburse(row, true)}
                                sx={{ mr: 1 }}
                              >
                                Disburse
                              </Button>
                            </>
                          )}
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => handleReject(row, true)}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                      {row.offers && row.offers.length > 0 && (
                        <TableRow>
                          <TableCell colSpan={Object.keys(columnLabelsLoanApplications).length + 2}>
                            <Typography variant="body2" color="textSecondary">
                              Offer Details:
                            </Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Loan Length</TableCell>
                                  <TableCell>Financial Institution</TableCell>
                                  <TableCell>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.offers.map((offer) => (
                                  <TableRow key={offer.id}>
                                    <TableCell>{offer.amount}</TableCell>
                                    <TableCell>{offer.loanLenght}</TableCell>
                                    <TableCell>{offer.financialInstitution?.companyName}</TableCell>
                                    <TableCell>{offer.offerStatus}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" gutterBottom>
              Invoice Funding Applications
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {Object.keys(columnLabelsInvoiceFundingApplications).map((key) => (
                      <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                        <TableSortLabel
                          active={orderBy === key}
                          direction={orderBy === key ? order : 'asc'}
                          onClick={() => handleSort(key)}
                        >
                          {columnLabelsInvoiceFundingApplications[key].label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceFundingApplications.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow style={getRowStyle(row)} onClick={() => handleRowClick(row, false)}>
                        <TableCell>
                          <IconButton size="small" onClick={() => handleRowClick(row, false)}>
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        {Object.keys(columnLabelsInvoiceFundingApplications).map((key) => (
                          <TableCell key={`${row.id}-${key}`}>{columnLabelsInvoiceFundingApplications[key].getValue(row)}</TableCell>
                        ))}
                        <TableCell>
                          {row.applicationStatusEnum === 'NewApplication' && (!row.offers || row.offers.length === 0) && (
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => handleOffer(row, false)}
                              sx={{ mr: 1 }}
                            >
                              Offer
                            </Button>
                          )}
                          {row.offers && row.offers.length > 0 && (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                color="warning"
                                onClick={() => handleEditOffer(row, row.offers[0], false)}
                                sx={{ mr: 1 }}
                              >
                                Edit Offer
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                onClick={() => handleDisburse(row, false)}
                                sx={{ mr: 1 }}
                              >
                                Disburse
                              </Button>
                            </>
                          )}
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={() => handleReject(row, false)}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                      {row.offers && row.offers.length > 0 && (
                        <TableRow>
                          <TableCell colSpan={Object.keys(columnLabelsInvoiceFundingApplications).length + 2}>
                            <Typography variant="body2" color="textSecondary">
                              Offer Details:
                            </Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Financial Institution</TableCell>
                                  <TableCell>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.offers.map((offer) => (
                                  <TableRow key={offer.id}>
                                    <TableCell>{offer.amount}</TableCell>
                                    <TableCell>{offer.financialInstitution?.companyName}</TableCell>
                                    <TableCell>{offer.offerStatus}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {tab === 1 && <BrokerYhteystiedot />}
        <DetailedInfoModal
          open={modalOpen}
          onClose={closeModal}
          application={selectedApplication}
          isLoan={isLoan}
          editMode={editMode}
          offer={selectedOffer}
        />
      </Container>
    </Box>
  );
}

export default BrokerDashboard;
