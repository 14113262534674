import React from 'react';
import { Container, Typography, Box, Link, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image

function UseinKysytytkysymykset() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, // Ensure the content fills available space
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', // Ensure the content fills available space
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Usein kysytyt kysymykset (UKK)
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >

päivitetty 31.05.2024
<br/><br/>        

<Container maxWidth="md" sx={{ mt: 8 }}>
            <Box sx={{ mb: 4 }}>

                <Typography variant="body1" sx={{ mb: 2 }}>
                    Olemme keränneet tälle sivulle yleisimpiä kysymyksiä koskien palveluamme ja rahoituksen vertailua. Tämän lisäksi voit aina olla yhteydessä asiakaspalveluumme <Link href="mailto:info@rahoituskanava.fi">info@rahoituskanava.fi</Link> muiden mieltä askarruttavien asioiden osalta.
                </Typography>
            </Box>
            <Typography
                    variant="body1"
                    sx={{
                        fontSize: { xs: '1.0rem', md: '1.1rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: 'none',
                    }}
                >
            <Box>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Miksi käyttää vertailupalvelua?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Vertailupalvelun avulla yritys voi löytää itselleen edullisimman ja sopivimman rahoitusratkaisun helposti ja nopeasti. Palvelu säästää aikaa ja resursseja, jotka voidaan suunnata yrityksen ydintoimintoihin. Palvelun käyttö on rahoituksen hakijalle maksutonta eikä velvoita hakijaa mihinkään.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Miten vertailupalvelu toimii?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Vertailupalvelu kerää eri rahoituspalveluiden tarjoajien tarjoukset ja esittelee ne yritykselle vertailtavaksi. Yritys voi valita parhaan tarjouksen ja hakea rahoitusta suoraan palvelun kautta. Pohjolan Rahoitusvertailu Oy tarjoaa tämän palvelun maksutta yrityksille.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Mitä hyötyä digitalisaatio tuo rahoituksen kilpailutukseen?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Digitalisaatio lisää läpinäkyvyyttä ja kilpailua rahoitusmarkkinoilla, mikä johtaa parempiin ehtoihin ja alhaisempiin kustannuksiin yrityksille. Se myös nopeuttaa rahoitusprosessia ja mahdollistaa nopeammat päätökset.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Mikä on Rahoituskanava?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Rahoituskanava on uusi kilpailutuspalvelu, joka auttaa yrityksiä löytämään parhaan rahoitusratkaisun. Palvelun kautta yritykset voivat kilpailuttaa <Link component={RouterLink} to="/laskurahoitus" sx={{ textDecoration: 'underline' }}>laskurahoituksen</Link> ja <Link component={RouterLink} to="/yrityslaina" sx={{ textDecoration: 'underline' }}>yrityslainat</Link> helposti ja nopeasti. Palvelun tarjoaa Pohjolan Rahoitusvertailu Oy.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Miten voin ottaa yhteyttä asiakaspalveluun?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Voit olla yhteydessä asiakaspalveluumme sähköpostitse osoitteessa <Link href="mailto:info@rahoituskanava.fi">info@rahoituskanava.fi</Link>. Asiakaspalvelumme auttaa sinua mielellään kaikissa kysymyksissä.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>

            </Typography>
        </Container>
             </Typography>               
            </Container>
        </Box>
    );
}

export default UseinKysytytkysymykset;
