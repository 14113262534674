import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {API_URL} from '../../constants';

import axios from 'axios';

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      
      const response = await axios.post(API_URL+'api/Users/login', credentials);
      localStorage.setItem('token', response.data.token);
      const user = JSON.parse(atob(response.data.token.split('.')[1])); // Decode the JWT token to get user info
      return { ...response.data, user };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem('token');
      return true;
    } catch (error) {
      return rejectWithValue(error.message);

    }
  }
);



