// CanonicalUrl.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CanonicalUrl = () => {
  const location = useLocation();
  const baseUrl = 'https://';
  const base2 = "rahoituskanava";
  const base3 = "fi";
  
  useEffect(() => {
    const canonicalLink = document.querySelector("link[rel='canonical']");
    const canonicalUrl = `${baseUrl}${base2}.${base3}${location.pathname}`;

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      const link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      
      document.head.appendChild(link);
    }
  }, [location]);

  return null;
};

export default CanonicalUrl;
