import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const TypewriterMessage = ({ content = '' }) => {
  if (typeof content !== 'string') {
    console.error('TypewriterMessage expects a string for content');
    return null;
  }

  const renderLink = ({ href, children }) => {
    
    return (
      <a
        href={href}
        target={'_self'}
        rel={'noopener noreferrer'}
      >
        {children}
      </a>
    );
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: renderLink,
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

export default TypewriterMessage;
