// src/Blogs.js

import React from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import heroImage from '../assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import blogs from './blogsData'; // Import the blogs data

function Blogs() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '30px',
                    padding: { xs: '20px', md: '40px'},
                }}
            >
                <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        fontWeight: 'bold',
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Vinkkejä rahoitukseen
                </Typography>
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: { xs: '1.2rem', md: '1.5rem' },
                        fontFamily: 'Lato, sans-serif',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    Rahoituskanava auttaa yritystäsi löytämään parhaat rahoitusratkaisut
                </Typography>
            </Container>
            <Container sx={{ mt: 8, p: 2, zIndex: 1 }}>
                <Grid container spacing={4}>
                    {blogs.map((blog) => (
                        <Grid item xs={12} md={6} key={blog.id}>
                            <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={blog.image}
                                    alt={blog.title}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        color="white"
                                        sx={{
                                            fontSize: { xs: '0.8rem', md: '0.8rem' },
                                            fontFamily: 'Lato, sans-serif',
                                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        {blog.date}, author: {blog.author}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        gutterBottom
                                        color="white"
                                        sx={{
                                            fontSize: { xs: '0.8rem', md: '1rem' },
                                            fontFamily: 'Lato, sans-serif',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        {blog.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        color="white"
                                        sx={{
                                            fontSize: { xs: '0.8rem', md: '0.8rem' },
                                            fontFamily: 'Lato, sans-serif',
                                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        {blog.excerpt}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={RouterLink} to={`/blogs/${blog.url}`} color="primary">
                                        Lue lisää
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ mt: 4, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                            fontFamily: 'Lato, sans-serif',
                            fontWeight: 'bold',
                            mb: 2,
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {[...Array(5)].map((_, index) => (
                            <StarIcon key={index} sx={{ color: '#FFD700' }} />
                        ))}{' '}
                        Viiden tähden kilpailutuspalvelu käytössäsi maksutta
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: 'Lato, sans-serif',
                            fontSize: { xs: '1.2rem', md: '1.5rem' },
                            fontWeight: 'bold',
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                            mb: 2,
                        }}
                    >
                        Löydät yhdellä hakemuksella yrityksellesi parhaan palvelun
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            fontFamily: 'Lato, sans-serif',
                            fontSize: { xs: '1rem', md: '1.5rem' },
                            fontWeight: 'bold',
                            color: 'white',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                            mb: 2,
                        }}
                    >
                        Nopeasti ja helposti. Kilpailuta{' '}
                        <RouterLink
                            to="/yrityslaina"
                            style={{ color: 'white', textDecoration: 'underline' }}
                        >
                            yrityslaina
                        </RouterLink>{' '}
                        ja{' '}
                        <RouterLink
                            to="/laskurahoitus"
                            style={{ color: 'white', textDecoration: 'underline' }}
                        >
                            laskurahoituspalvelut
                        </RouterLink>{' '}
                        kätevästi Rahoituskanavassa.
                    </Typography>
                </Box>
                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/yrityslaina"
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'Lato, sans-serif',
                            padding: '10px 20px',
                            backgroundColor: '#FFD700',
                            '&:hover': {
                                backgroundColor: '#DAA520',
                            },
                        }}
                        aria-label="Go to Business Loan page"
                    >
                        Yrityslaina
                    </Button>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/laskurahoitus"
                        sx={{
                            textTransform: 'none',
                            fontFamily: 'Lato, sans-serif',
                            padding: '10px 20px',
                            backgroundColor: '#FFD700',
                            '&:hover': {
                                backgroundColor: '#DAA520',
                            },
                        }}
                        aria-label="Go to Invoice Financing page"
                    >
                        Laskurahoitus
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}

export default Blogs;
