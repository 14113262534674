// companyLoanApplicationsActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../constants';

export const fetchCompanyLoanApplications = createAsyncThunk(
  'companyLoanApplications/fetchCompanyLoanApplications',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await fetch(`${API_URL}api/CompanyLoanApplications/UserLoanApplications`, {
        headers: {
          
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch company loan applications');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching company loan applications:', error.message);
      return rejectWithValue(error.message);
    }
  }
);
