import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, CircularProgress, Box } from '@mui/material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (auth.isUser) {
                navigate('/omat-sivut');
            } else if (auth.isAdmin) {
                navigate('/admin-dashboard');
            } else if (auth.isBroker) {
                navigate('/broker-dashboard');
            }
        }
    }, [auth.isAuthenticated, auth.isUser, auth.isAdmin, auth.isBroker, navigate]);

    useEffect(() => {
        setIsValid(email !== '' && password !== '');
    }, [email, password]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValid) {
            dispatch(login({ email, password }));
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Login
                </Typography>
                {auth.loading && <CircularProgress />}
                {auth.error && <Typography color="error">{auth.error}</Typography>}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%' }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
