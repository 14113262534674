import React from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    IconButton,
    Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const columnLabelsCompanyLoan = {
    amount: { label: 'Määrä (€)', getValue: (amount) => amount?.toFixed(2) },
    loanLenght: { label: 'Laina-aika (kk)', getValue: (loanLenght) => loanLenght || 'ei aikaa' },
    applicationStatusEnum: {
        label: 'Tila',
        getValue: (status) => {
            const statusMapping = {
                NewApplication: 'Uusi',
                InProcessApplication: 'Kilpailutuksessa',
                ClosedApplication: 'Valmis',
                DeletedApplication: 'Peruutettu'
            };
            return statusMapping[status] || 'ei tilaa';
        },
    },
    createdDate: {
        label: 'Luontipäivä',
        getValue: (date) => (date ? new Date(date).toLocaleString('fi-FI') : 'ei luontia'),
    },
    closeDate: {
        label: 'Päättymispäivä',
        getValue: (date) => (date ? new Date(date).toLocaleString('fi-FI') : 'ei päättymistä'),
    },
    reasonForEnd: {
        label: 'Päättymisen syy',
        getValue: (reason) =>
            ['', 'Vanhentunut', 'Valmis', 'Peruutettu yrityksen toimesta', 'Peruutettu käyttäjän toimesta'][reason] || '-',
    },
};

const CompanyLoanApplicationsComponent = ({ applications, order, orderBy, handleSort, handleEditClick, handlePeruutettu, handleSetActive }) => {
    const getRowStyle = (status) => {
        switch (status) {
            case 'InProcessApplication':
                return { backgroundColor: '#a3d6a7', color: 'black' }; // Light Green
            case 'DeletedApplication':
                return { backgroundColor: 'lightgray' };
            default:
                return {};
        }
    };

    return (
        <>
            <Typography variant="body1" gutterBottom>
                Company Loan Applications
            </Typography>
            <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {Object.keys(columnLabelsCompanyLoan).map((key) => (
                                <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === key}
                                        direction={orderBy === key ? order : 'asc'}
                                        onClick={() => handleSort(key)}
                                    >
                                        {columnLabelsCompanyLoan[key].label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>Company</TableCell>
                            <TableCell>Offers</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow style={getRowStyle(row.applicationStatusEnum)}>
                                    {Object.keys(columnLabelsCompanyLoan).map((key) => (
                                        <TableCell key={`${row.id}-${key}`}>{columnLabelsCompanyLoan[key].getValue(row[key])}</TableCell>
                                    ))}
                                    <TableCell>{row.company?.companyName || 'No company info'}</TableCell>
                                    <TableCell>
                                        {row.offers?.length > 0 ? (
                                            row.offers.map((offer) => (
                                                <Typography key={offer.id}>
                                                    {offer.amount.toFixed(2)} € from {offer.financialInstitution?.companyName}
                                                </Typography>
                                            ))
                                        ) : (
                                            'No offers'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(row)}><EditIcon /></IconButton>
                                        {row.applicationStatusEnum === 'NewApplication' || row.applicationStatusEnum === 'InProcessApplication' ? (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={() => handlePeruutettu(row)}
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                onClick={() => handleSetActive(row)}
                                            >
                                                Set as Active
                                            </Button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CompanyLoanApplicationsComponent;
