import React, { Suspense, lazy } from 'react';
import { Container, Box, Typography, Grid, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import blogs from './blogsData';
import heroImage from '../assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image

const BlogPage = () => {
    const { blogUrl } = useParams();
    const blog = blogs.find(b => b.url === blogUrl);

    if (!blog) {
        return <Typography variant="h5" gutterBottom>Blog not found</Typography>;
    }

    const BlogContent = lazy(() => import(`./${blog.content}`));

    // Get the 3 newest blogs excluding the current one
    const newestBlogs = blogs
        .filter(b => b.url !== blogUrl)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                position: 'relative',
                flex: 1, 
                fontFamily: 'Lato, sans-serif'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    minHeight: '100vh',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                    zIndex: 0,
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: '#333333',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: '50px',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto',
                    fontFamily: 'Lato, sans-serif', 
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Dark overlay
                    borderRadius: '5px'

                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontSize: { xs: '1rem', md: '1.2rem' },
                        fontWeight: 'bold',
                        textShadow: '2px 2px 4px rgba(255, 255, 255, 0.5)',
                    }}
                >
                    {blog.date}, Author: {blog.author}
                </Typography>
                <Suspense fallback={<div>Loading...</div>}>
                    <BlogContent sx={{ 
                        '& h3': { fontSize: '2rem', fontWeight: 'bold', mt: 4, mb: 2 },
                        '& h4': { fontSize: '1.5rem', fontWeight: 'bold', mt: 3, mb: 1.5 },
                        '& h5': { fontSize: '1.25rem', fontWeight: 'bold', mt: 2.5, mb: 1 },
                    }} />
                </Suspense>
            </Container>
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: 'white',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    padding: { xs: '20px', md: '40px' },
                    flex: '1 0 auto', 
                    fontFamily: 'Lato, sans-serif'
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Muita artikkeleitamme
                </Typography>
                <Grid container spacing={4}>
                    {newestBlogs.map((newBlog) => (
                        <Grid item xs={12} md={4} key={newBlog.id}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={newBlog.image}
                                    alt={newBlog.title}
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {newBlog.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {newBlog.excerpt}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button component={RouterLink} to={`/blogs/${newBlog.url}`} size="small" color="primary">
                                        Lue lisää
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default BlogPage;
