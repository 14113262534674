// invoiceFundingApplicationsActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../constants';

export const fetchInvoiceFundingApplications = createAsyncThunk(
  'invoiceFundingApplications/fetchInvoiceFundingApplications',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(API_URL + 'api/InvoiceFundingApplications/UserInvoiceFundingApplications', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'

        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
