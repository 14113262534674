import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Button,
    Tabs,
    Tab,
    IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Yhteystiedot from './Yhteystiedot';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Placeholder for the hero image
import { API_URL } from './constants';

const columnLabels = {
    type: { label: 'Tyyppi', getValue: (type) => (type === 1 ? 'Yrityslaina' : 'Laskurahoitus') },
    amount: { label: 'Määrä (€)', getValue: (amount) => amount.toFixed(2) },
    loanLenght: { label: 'Laina-aika (kk)', getValue: (loanLenght) => loanLenght || 'ei aikaa' },
    applicationStatusEnum: {
        label: 'Tila',
        getValue: (status) => ['Uusi', 'Kilpailutuksessa', 'Valmis', 'Peruutettu'][status] || 'ei tilaa',
    },
    createdDate: {
        label: 'Luontipäivä',
        getValue: (date) => (date ? new Date(date).toLocaleString('fi-FI') : 'ei luontia'),
    },
    closeDate: {
        label: 'Päättymispäivä',
        getValue: (date) => (date ? new Date(date).toLocaleString('fi-FI') : 'ei päättymistä'),
    },
    reasonForEnd: {
        label: 'Päättymisen syy',
        getValue: (reason) =>
            ['', 'Vanhentunut', 'Valmis', 'Peruutettu yrityksen toimesta', 'Peruutettu käyttäjän toimesta'][reason] || '-',
    },
};

function OmatSivut() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = localStorage.getItem('token');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [loanApplications, setLoanApplications] = useState([]);
    const [invoiceFundingApplications, setInvoiceFundingApplications] = useState([]);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (token) {
            fetchApplications();
        }
    }, [token]);

    const fetchApplications = async () => {
        try {
            const loanResponse = await fetch(`${API_URL}api/CompanyLoanApplications/UserLoanApplications`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (loanResponse.ok) {
                const loanData = await loanResponse.json();
                setLoanApplications(loanData);
            } else {
                console.error('Error fetching loan applications:', loanResponse.statusText);
            }

            const invoiceResponse = await fetch(`${API_URL}api/InvoiceFundingApplications/UserInvoiceFundingApplications`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (invoiceResponse.ok) {
                const invoiceData = await invoiceResponse.json();
                setInvoiceFundingApplications(invoiceData);
            } else {
                console.error('Error fetching invoice funding applications:', invoiceResponse.statusText);
            }
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const sortedData = [...loanApplications].sort((a, b) => {
            if (a[property] < b[property]) return isAsc ? -1 : 1;
            if (a[property] > b[property]) return isAsc ? 1 : -1;
            return 0;
        });

        setLoanApplications(sortedData);
    };

    const handlePeruutettu = (id, isLoan) => {
        if (isLoan) {
            setLoanApplications((prev) =>
                prev.map((app) =>
                    app.id === id
                        ? { ...app, status: 3, closeDate: new Date().toISOString(), reasonForEnd: 4 } // Cancelled by User
                        : app
                )
            );
        } else {
            setInvoiceFundingApplications((prev) =>
                prev.map((app) =>
                    app.id === id
                        ? { ...app, status: 3, closeDate: new Date().toISOString(), reasonForEnd: 4 } // Cancelled by User
                        : app
                )
            );
        }
    };

    const getRowStyle = (status) => {
        switch (status) {
            case 1:
                return { backgroundColor: '#a3d6a7', color: 'black' }; // Light Green
            case 3:
                return { backgroundColor: 'lightgray' };
            default:
                return {};
        }
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleRefresh = () => {
        fetchApplications();
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${heroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0)', // Light background overlay
                }}
            />
            <Container
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background color
                    padding: { xs: 2, md: 4 },
                    borderRadius: 2,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    width: '100%',
                    textAlign: 'left',
                    color: '#333333', // Dark text color
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    mt: { xs: 8, md: 8 }, // Add top margin for mobile view
                }}
            >
                <IconButton onClick={handleRefresh} aria-label="refresh">
                    <RefreshIcon />
                </IconButton>
                <Tabs value={tab} onChange={handleTabChange} aria-label="omat sivut tabs">
                    <Tab label="Lainahakemukset" />
                    <Tab label="Yhteystiedot" />
                </Tabs>
                {tab === 0 && (
                    <>
                        <Typography variant="body1" gutterBottom>
                            Yrityslainat
                        </Typography>
                        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(columnLabels).map((key) => (
                                            <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === key}
                                                    direction={orderBy === key ? order : 'asc'}
                                                    onClick={() => handleSort(key)}
                                                >
                                                    {columnLabels[key].label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell>Toiminnot</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loanApplications.map((row) => (
                                        <TableRow key={row.id} style={getRowStyle(row.status)}>
                                            {Object.keys(columnLabels).map((key) => (
                                                <TableCell key={`${row.id}-${key}`}>{columnLabels[key].getValue(row[key])}</TableCell>
                                            ))}
                                            <TableCell>
                                                {row.status === 0 || row.status === 1 ? (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => handlePeruutettu(row.id, true)}
                                                    >
                                                        Peruuta
                                                    </Button>
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="body1" gutterBottom>
                            Laskurahoitukset
                        </Typography>
                        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(columnLabels).map((key) => (
                                            <TableCell key={key} sortDirection={orderBy === key ? order : false}>
                                                <TableSortLabel
                                                    active={orderBy === key}
                                                    direction={orderBy === key ? order : 'asc'}
                                                    onClick={() => handleSort(key)}
                                                >
                                                    {columnLabels[key].label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell>Toiminnot</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoiceFundingApplications.map((row) => (
                                        <TableRow key={row.id} style={getRowStyle(row.status)}>
                                            {Object.keys(columnLabels).map((key) => (
                                                <TableCell key={`${row.id}-${key}`}>{columnLabels[key].getValue(row[key])}</TableCell>
                                            ))}
                                            <TableCell>
                                                {row.status === 0 || row.status === 1 ? (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => handlePeruutettu(row.id, false)}
                                                    >
                                                        Peruuta
                                                    </Button>
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
                {tab === 1 && <Yhteystiedot />}
            </Container>
        </Box>
    );
}

export default OmatSivut;
