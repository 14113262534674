import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box, Container, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Menu as MenuIcon, Settings, Home, Info, AccountBalance, Article } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './features/auth/authSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function Navbar() {
    const [productsAnchorEl, setProductsAnchorEl] = useState(null);
    const [brokerAnchorEl, setBrokerAnchorEl] = useState(null);
    const [adminAnchorEl, setAdminAnchorEl] = useState(null);

    const [kanavaAnchorEl, setKanavaAnchorEl] = useState(null);


    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, isUser, isBroker, isAdmin, user } = useSelector((state) => state.auth);

    const handleProductsClick = (event) => {
        setProductsAnchorEl(event.currentTarget);
    };

    const handleBrokerClick = (event) => {
        setBrokerAnchorEl(event.currentTarget);
    };
    const handleAdminClick = (event) => {
        setAdminAnchorEl(event.currentTarget);
    };


    const handleKanavaClick = (event) => {
        setKanavaAnchorEl(event.currentTarget);
    };

    const handleProductsClose = () => {
        setProductsAnchorEl(null);
    };

    const handleKanavaClose = () => {
        setKanavaAnchorEl(null);
    };
    const handleBrokerClose = () => {
        setBrokerAnchorEl(null);
    };
    const handleAdminClose = () => {
        setAdminAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const handleScroll = () => {
        setScroll(window.scrollY > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: scroll ? 'rgba(0, 0, 0, 0.8)' : 'transparent', transition: 'background-color 0.3s', boxShadow: 'none' }}>
            <Container maxWidth="lg">
                <Toolbar>
                    <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'white', fontFamily: 'Lato, sans-serif' }}>
                        Rahoituskanava.fi
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button color="inherit" component={Link} to="/" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}>Etusivu</Button>
                        <Button
                            color="inherit"
                            onClick={handleProductsClick}
                            sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}
                            endIcon={
                                <ArrowDropDownIcon sx={{ color: scroll ? 'gold' : '#fff' }} />
                            }
                        >
                            Rahoitustuotteet
                        </Button>
                        <Menu
                            anchorEl={productsAnchorEl}
                            open={Boolean(productsAnchorEl)}
                            onClose={handleProductsClose}
                            MenuListProps={{
                                sx: {
                                    backgroundColor: '#333',
                                    color: '#fff',
                                },
                            }}
                        >
                            <MenuItem onClick={handleProductsClose} component={Link} to="/yrityslaina" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Yrityslaina" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleProductsClose} component={Link} to="/laskurahoitus" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Laskurahoitus" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <hr />
                            <MenuItem onClick={handleProductsClose} component={Link} to="/yrityslaina/miten-valmistautua-kilpailutukseen" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Vinkit yrityslainaan" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleProductsClose} component={Link} to="/laskurahoitus/miten-valmistautua-kilpailutukseen" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Vinkit laskurahoitukseen" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                        </Menu>
                        <Button
                            color="inherit"
                            onClick={handleKanavaClick}
                            sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}
                            endIcon={
                                <ArrowDropDownIcon sx={{ color: scroll ? 'gold' : '#fff' }} />
                            }
                        >
                            Rahoituskanava.fi
                        </Button>
                        <Menu
                            anchorEl={kanavaAnchorEl}
                            open={Boolean(kanavaAnchorEl)}
                            onClose={handleKanavaClose}
                            MenuListProps={{
                                sx: {
                                    backgroundColor: '#333',
                                    color: '#fff',
                                },
                            }}
                        >
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/useinkysytytkysymykset" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Usein kysytyt kysymykset" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/about" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Tietoa meistä" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <hr />
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/kayttoehdot" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Käyttöehdot" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/tietosuojaseloste" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Tietosuojaseloste" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
                            </MenuItem>
                        </Menu>
                        <Button color="inherit" component={Link} to="/blogs" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}>Blogit</Button>
                        {isAuthenticated ? (
                            <>
                                <Typography variant="body1" color="inherit" sx={{ marginRight: '16px', fontFamily: 'Lato, sans-serif', color: 'white' }}>
                                    {user.name}
                                </Typography>
                                {isUser && (
                                    <Button color="inherit" component={Link} to="/omat-sivut" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}>
                                        Omat sivut
                                    </Button>
                                )}
                                {isBroker && (

<>
   
<Button
color="inherit"
onClick={handleBrokerClick}
sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'lightblue' }}
endIcon={
    <ArrowDropDownIcon sx={{ color: scroll ? 'gold' : '#fff' }} />
}
>
Rahoitusyhtiö
</Button>
<Menu
anchorEl={brokerAnchorEl}
open={Boolean(brokerAnchorEl)}
onClose={handleBrokerClose}
MenuListProps={{
    sx: {
        backgroundColor: '#333',
        color: '#fff',
        fontSize: '0.7rem'
    },
}}
>
<MenuItem onClick={handleBrokerClose} component={Link} to="/broker-dashboard?tab=0" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.7rem' }}>
    <ListItemText primary="Rahoitushakemukset" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
</MenuItem>
<MenuItem onClick={handleBrokerClose} component={Link} to="/broker-dashboard?tab=1" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.8rem' }}>
    <ListItemIcon><Settings fontSize="small" sx={{color: 'lightblue'}}/></ListItemIcon>
    <ListItemText primary="Asetukset" primaryTypographyProps={{ fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }} />
</MenuItem>
</Menu>
</>


                                )}
                                {isAdmin && (
                                    <Button color="inherit" component={Link} to="/admin-dashboard" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'red' }}>
                                        Admin dashboard
                                    </Button>
                                )}
                                <Button color="inherit" onClick={handleLogout} sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}>
                                    Kirjaudu ulos
                                </Button>
                            </>
                        ) : (
                            <Button color="inherit" component={Link} to="/login" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', color: 'white' }}>Kirjaudu</Button>
                        )}
                    </Box>
                    <IconButton
                        color="inherit"
                        edge="end"
                        sx={{ display: { xs: 'block', md: 'none' }, color: 'pink' }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </Container>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250, backgroundColor: '#333', color: '#fff' }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button component={Link} to="/">
                            <ListItemIcon sx={{ color: '#fff' }}><Home /></ListItemIcon>
                            <ListItemText primary="Etusivu" />
                        </ListItem>
                        <ListItem button onClick={handleProductsClick}>
                            <ListItemIcon sx={{ color: '#fff' }}><AccountBalance /></ListItemIcon>
                            <ListItemText primary="Rahoitustuotteet" />
                            <ExpandMoreIcon />
                        </ListItem>
                        <Menu
                            anchorEl={productsAnchorEl}
                            open={Boolean(productsAnchorEl)}
                            onClose={handleProductsClose}
                            MenuListProps={{
                                sx: {
                                    backgroundColor: '#333',
                                    color: '#fff',
                                },
                            }}
                        >
                            <MenuItem onClick={handleProductsClose} component={Link} to="/yrityslaina" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Yrityslaina" sx={{ fontFamily: 'Lato, sans-serif', fontSize: '0.8rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleProductsClose} component={Link} to="/laskurahoitus" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Laskurahoitus" sx={{ fontFamily: 'Lato, sans-serif', fontSize: '0.8rem' }} />
                            </MenuItem>
                        </Menu>
                        <ListItem button onClick={handleKanavaClick}>
                            <ListItemIcon sx={{ color: '#fff' }}><Info /></ListItemIcon>
                            <ListItemText primary="Rahoituskanava" />
                            <ExpandMoreIcon />
                        </ListItem>
                        <Menu
                            anchorEl={kanavaAnchorEl}
                            open={Boolean(kanavaAnchorEl)}
                            onClose={handleKanavaClose}
                            MenuListProps={{
                                sx: {
                                    backgroundColor: '#333',
                                    color: '#fff',
                                },
                            }}
                        >
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/useinkysytytkysymykset" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Usein kysytyt kysymykset" sx={{ fontFamily: 'Lato, sans-serif', fontSize: '0.8rem' }} />
                            </MenuItem>
                            <MenuItem onClick={handleKanavaClose} component={Link} to="/about" sx={{ textTransform: 'none', fontFamily: 'Lato, sans-serif', fontSize: '0.875rem' }}>
                                <ListItemText primary="Tietoa meistä" sx={{ fontFamily: 'Lato, sans-serif', fontSize: '0.8rem' }} />
                            </MenuItem>
                        </Menu>
                        <ListItem button component={Link} to="/blogs">
                            <ListItemIcon sx={{ color: '#fff' }}><Article /></ListItemIcon>
                            <ListItemText primary="Blogit" />
                        </ListItem>

                        {isAuthenticated && (
                            <>
                                <ListItem>
                                    <ListItemText primary={user.name} />
                                </ListItem>
                                {isUser && (
                                    <ListItem button component={Link} to="/omat-sivut">
                                        <ListItemText primary="Omat sivut" />
                                    </ListItem>
                                )}
                                {isBroker && (
                                    <ListItem button component={Link} to="/broker-dashboard">
                                        <ListItemText primary="Broker dashboard" />
                                    </ListItem>
                                )}
                                {isAdmin && (
                                    <ListItem button component={Link} to="/admin-dashboard">
                                        <ListItemText primary="Admin dashboard" sx={{ color: 'red' }} />
                                    </ListItem>
                                )}
                                <ListItem button onClick={handleLogout}>
                                    <ListItemText primary="Kirjaudu ulos" />
                                </ListItem>
                            </>
                        )}
                        {!isAuthenticated && (
                            <ListItem button component={Link} to="/login">
                                <ListItemText primary="Kirjaudu" />
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Drawer>
        </AppBar>
    );
}

export default Navbar;
